import React from "react";
import { Form } from 'react-bootstrap';
import { CloudUpload } from 'react-bootstrap-icons';
import { Upload, Button } from 'antd';

const allowedFileTypes = [
  "text/csv"
];

export const ImportHydrants = ({onChange, onRemove}) => {
  return (
    <div className='mb-3'>
      <Form.Label htmlFor='email'>Plik CSV <a href="/assets/hydrants.csv" download="hydranty.csv" className="sample-link">Pobierz wzór do wypełnienia</a></Form.Label>
      <div style={{maxWidth: '200px'}}>
        <Upload
          accept={allowedFileTypes}
          onChange={onChange}
          onRemove={onRemove}
          beforeUpload={() => false}
          maxCount={1}
        >
          <Button icon={<CloudUpload />}> Wybierz Plik</Button>
        </Upload>
      </div>
    </div>
  );
}