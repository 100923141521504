import React, { useContext } from "react";
import { Formik } from 'formik';
import { Form, Alert, Container, Row, Col } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import { CHANGE_SETTINGS_MUTATION } from '../../common/mutations/user';
import { CURRENT } from '../../common/queries/user';
import { LoadingButton } from "../UI/LoadingButton/LoadingButton";
import { PrivateComponent } from "../Authorization/PrivateComponent";
import { LoadingSpinner } from "../UI/LoadingSpinner";
import moment from 'moment';

const FormComponent = ({ onCompleted, token }) => {
  const [onSubmit, { loading, data = { changeUserSettings: {}} }] = useMutation(CHANGE_SETTINGS_MUTATION, {
    onCompleted
  });

  const { loading: userLoading, data: userData = { user: {}} } = useQuery(CURRENT, {
    fetchPolicy: 'cache-and-network',
  });

  if (userLoading) {
    return <LoadingSpinner />
  } else {

  return (
    <Formik
      onSubmit={(data) => onSubmit({
        variables: {
          ...data,
          inspectionDate: data.inspectionDate && data.inspectionDate.length > 0 ? data.inspectionDate : null
        }
       })}
      initialValues={{
        inspectionDate: userData.user.inspectionDate ? moment(userData.user.inspectionDate).format("YYYY-MM-DD") : null,
        hardware: userData.user.hardware,
       }}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
      }) => (
      <Form onSubmit={handleSubmit}>
         <Row>
            <Col>
              <Form.Label htmlFor='inspectionDate'>Data inspekcji</Form.Label>
              <Form.Control
                id='inspectionDate'
                name='inspectionDate'
                type='date'
                placeholder='Data inspekcji'
                value={values.inspectionDate}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Label htmlFor='hardware'>Sprzęt pomiarowy</Form.Label>
                <Form.Control
                  type='text'
                  name='hardware'
                  id='hardware'
                  value={values.hardware}
                  onChange={handleChange}
                />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <LoadingButton
                loading={loading}
                type="submit">
                  Zapisz
              </LoadingButton>
            </Col>
          </Row>
          { data.changeUserSettings.user && <Alert variant="success">Ustawienia zostały zmienione</Alert>}
        </Form>
      )}
    </Formik>
  );
      }
}

export const ChangeSettings = () => {

  return (
    <PrivateComponent>
      <Container className='mt-5'>
        <Row>
          <Col><h4>Zmień ustawienia</h4></Col>
        </Row>
        <Row>
          <Col>
            <FormComponent />
          </Col>
        </Row>
      </Container>
    </PrivateComponent>
  );
}
