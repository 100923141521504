import React from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { SHOW_WITH_INSPECTIONS } from "../../common/queries/hydrants";
import { PrivateComponent } from '../Authorization/PrivateComponent';

export const HydrantInspectionsList = () => {
  const { hydrantId } = useParams();
  const { loading, data = { hydrant: { inspections: []}} } = useQuery(SHOW_WITH_INSPECTIONS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: parseInt(hydrantId)
    }
  });

  return (
    <PrivateComponent>
      <Row>
        <Col><h4>Lista inspekcji</h4></Col>
      </Row>
      <Row>
        <Col>
          <ListGroup variant="flush">
            {data.hydrant.inspections.map(inspection =>
              <ListGroup.Item key={inspection.id}>{inspection.createdAt}</ListGroup.Item>
            )}
          </ListGroup>
        </Col>
      </Row>
    </PrivateComponent>
  );
}