import { createSlice } from '@reduxjs/toolkit'

export const exportJobsSlice = createSlice({
  name: 'exportJobs',
  initialState: {
    value: {}
  },
  reducers: {
    addJob: (state, action) => {
      let job = action.payload;
      state.value[job.id] = job;
    },
    updateJob: (state, action) => {
      let updatedJob = action.payload;
      if (state.value[updatedJob.id]) {
        state.value[updatedJob.id] = updatedJob;
      }
    },
    deleteJob: (state, action) => {
      let job = action.payload;
      delete state.value[job.id];
    },
  },
})

export const { addJob, updateJob, deleteJob } = exportJobsSlice.actions

export const selectJobs = (state) => state.exportJobs?.value || {}

export default exportJobsSlice.reducer;
