import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from "react-router-dom";
import Map from '../Map/Map';
import { useQuery } from "@apollo/client";
import { INDEX } from "../../common/queries/hydrants";
import { PrivateComponent } from '../Authorization/PrivateComponent';

export const Index = ({children}) => {
  const [currentPosition, setCurrentPosition] = useState({ lat: 52.22864058406609, lng: 21.005172729492188 });
  const { data = { hydrants: []} } = useQuery(INDEX, {
    fetchPolicy: 'cache-and-network',
    variables: {
      latitude: currentPosition && currentPosition.lat && currentPosition.lat.toString(),
      longitude: currentPosition && currentPosition.lng && currentPosition.lng.toString()
    }
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log('geolocation permission state is ', result.state);
          if (result.state === "granted") {
            console.log(result.state);
            //If granted then you can directly call your function here
          } else if (result.state === "prompt") {
            console.log(result.state);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else  if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        //imitate map latlng construct
        const marker = { 
          lat: position.coords.latitude, 
          lng: position.coords.longitude 
        };
      })
    } else {
      alert("Sorry Not available!");
    }
  });

  return (
    <PrivateComponent>
      <Map
        height={'80vh'}
        width={'100%'}
        fullscreenControl={false}
        markers={data.hydrants}
        setCurrentPosition={setCurrentPosition}
        currentPosition={currentPosition}
        onMarkerClick={(hydrant) => navigate(`/hydrant/${hydrant.id}`)}
      />
      <Outlet />
    </PrivateComponent>
  );
}
