import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, Outlet, useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { SHOW } from "../../common/queries/plans";
import { Table, Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Map from "../Map/Map";
import { statusMap } from '../../common/statuses';
import moment from 'moment';
import {PlusCircle} from "react-bootstrap-icons";
import { Button as BootstrapButton } from "react-bootstrap";

export const PlanDetails = () => {
  const [currentPosition, setCurrentPosition] = useState();
  const [searchPhrase, setSearchPhrase] = useState("");
  const [filters, setFilters] = useState({});
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");

  const { planId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams({ page: 1});
  let page = searchParams.get("page") || 1

  const navigate = useNavigate();
  const { loading, data = { plan: { inspectionsTotal: 1, inspections: [], mapInspections: [] }} } = useQuery(SHOW, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: filters,
      searchPhrase: searchPhrase,
      id: parseInt(planId),
      page: parseInt(page),
      latitude: currentPosition && currentPosition.lat && currentPosition.lat.toString(),
      longitude: currentPosition && currentPosition.lng && currentPosition.lng.toString()
    }
  });
  const goToAddHydrant = () => navigate(`/admin/hydrants/new?planId=${planId}`);

  const inspectionEditLink = (planId, inspectionId) => {
    return `/plans/${planId}/inspections/${inspectionId}/edit?page=${page}`;
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchedColumn(dataIndex);
              setSearchText(selectedKeys[0]);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  });

  const columns = (setCurrentPosition) => ([
    {
      title: 'Numer, Okręg',
      dataIndex: 'objectCode',
      render: (_text, record) => <div><Link
        onClick={() => setCurrentPosition({
          lat: parseFloat(record.latitude),
          lng: parseFloat(record.longitude)
        })}
        to={inspectionEditLink(record.planId, record.id)}>{record.hydrant.objectCode}</Link>
        <div>{record.hydrant.district}</div>
      </div>,
      ...getColumnSearchProps('objectCode'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: 'Do Zrobienia',
          value: 'TO_DO',
        },
        {
          text: 'Zrobione',
          value: 'DONE',
        },
        {
          text: 'Konflikt',
          value: 'CONFLICT',
        },
      ],
      render: (_text, record) => statusMap[record.status]
    },
    {
      title: 'Wykonawca',
      dataIndex: 'user',
      ellipsis: true,
      render: (_text, record) => record.user?.email
    }
  ]);

  const firstInspection = data.plan.inspections[0];

  if (firstInspection && firstInspection.latitude && firstInspection.longitude && !currentPosition) {
    setCurrentPosition({
      lat: parseFloat(firstInspection.latitude),
      lng: parseFloat(firstInspection.longitude),
    });
  }

  function handleTableChange(pagination, filters, sorter, extra) {
    setFilters(filters);
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <div className="pb-3">
      <Row>
        <Col><h5>Plan na dzień {moment(data.plan.date).format("DD.MM.YYYY")}</h5></Col>
      </Row>
      <Row>
        <Col className="relative">
          <Map
            height={'80vh'}
            width={'100%'}
            fullscreenControl={false}
            markers={data.plan.mapInspections}
            onMarkerClick={(inspection) => navigate(inspectionEditLink(inspection.planId, inspection.id))}
            setCurrentPosition={setCurrentPosition}
            currentPosition={currentPosition}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex justify-content-end">
          <BootstrapButton variant="primary" size="sm" onClick={goToAddHydrant} >
            <PlusCircle/> Dodaj hydrant
          </BootstrapButton>
        </Col>
      </Row>
      <Row className="mt-2 mb-5">
        <Col>
          <Table
            rowKey="id"
            columns={columns(setCurrentPosition)}
            dataSource={data.plan.inspections}
            onChange={handleTableChange}
            pagination={{
              total: data.plan.inspectionsTotal,
              onChange: (page) => setSearchParams({ page }),
              current: parseInt(page),
              defaultPageSize: data.perPage,
              pageSize: data.perPage,
              showSizeChanger: false
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Outlet />
        </Col>
      </Row>
    </div>
  );
}
