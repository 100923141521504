import React, { useState } from 'react';
import Map from '../../Map/Map';
import { Transfer } from 'antd';
import { Row, Col, Badge } from 'react-bootstrap';
import { LoadingSpinner } from "../../UI/LoadingSpinner";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import moment from 'moment';
import { INDEX as HYDRANTS_INDEX } from "../../../common/queries/hydrants";
import { INDEX_WITH_HYDRANT_IDS as PLANS_INDEX } from "../../../common/queries/plans";

export const HydrantTransfer = (props) => {
  const filterTransferOption = (inputValue, option) => {
    return option.description.indexOf(inputValue) > -1;
  }

  const handleTransferChange = (nextTargetKeys, _direction, _moveKeys) => {
    setTargetHydrants(hydrantsDataSource.filter(element => nextTargetKeys.indexOf(element.key) >= 0));
    setTargetKeys(nextTargetKeys);
    props.onHydrantsChange(nextTargetKeys);
  }

  const buildDataSource = (hydrants, previousDataSource) => {
    let resultWithDuplicates = [];

    hydrants.forEach((hydrant, index) => {
      const data = {
        key: hydrant.id,
        title: hydrant.objectCode,
        description: hydrant.district,
      };
      resultWithDuplicates.push(data);
    });

    resultWithDuplicates = resultWithDuplicates.concat(previousDataSource);

    return _.uniqBy(resultWithDuplicates, (element) => { return element.key });
  }

  const existingPlan = props.plan;
  const plansHydrants = existingPlan?.inspections?.map(inspection => inspection.hydrant) || [];
  const plansHydrantsIds = plansHydrants.map(hydrant => hydrant.id);
  const plansHydrantsDataSource = buildDataSource(plansHydrants, []);

  const [currentPosition, setCurrentPosition] = useState({ lat: 52.22864058406609, lng: 21.005172729492188 });
  const [targetHydrants, setTargetHydrants] = useState(plansHydrantsDataSource);
  const [targetKeys, setTargetKeys] = useState(plansHydrantsIds);
  const plansQuery = useQuery(PLANS_INDEX, {
    fetchPolicy: 'cache-and-network',
    variables: {
      date: props.date &&  moment(props.date).format("YYYY-MM-DD"),
    }
  })
  const plansData = plansQuery.data;
  const plans = plansData?.plans || [];
  const plansByHydrantId = plans.reduce(
    (previousValue, plan) => {
      plan.hydrants.forEach((hydrant) => {
        previousValue[hydrant.id] ||= [];
        previousValue[hydrant.id].push({id: plan.id, name: plan.name});
      });
      return previousValue;
    },
    {}
  );
  const hydrantsQuery = useQuery(HYDRANTS_INDEX, {
    fetchPolicy: 'cache-and-network',
    variables: {
      latitude: currentPosition && currentPosition.lat && currentPosition.lat.toString(),
      longitude: currentPosition && currentPosition.lng && currentPosition.lng.toString(),
      paginate: false,
    }
  });

  const hydrantsData = hydrantsQuery.data || hydrantsQuery.previousData;
  const hydrants = hydrantsData?.hydrants || [];
  const hydrantsDataSource = buildDataSource(hydrants, targetHydrants);
  const notAllHydrantsDisplayed = hydrantsData && hydrantsData.limit < hydrantsData.hydrantsTotal;

  // const firstHydrant = hydrants[0];
  
  // if (firstHydrant && firstHydrant.latitude && firstHydrant.longitude && currentPosition.lat == 52.33323836 && currentPosition.lng == 21.006506518) {
  //   setCurrentPosition({
  //     lat: parseFloat(firstHydrant.latitude),
  //     lng: parseFloat(firstHydrant.longitude),
  //   });
  // }

  if (!props.date) {
    return null;
  }

  return (
    <div>
      {notAllHydrantsDisplayed ? <p>Załadowano najbliższe {hydrantsData.limit} hydrantów spośród {hydrantsData.hydrantsTotal} w tym regionie</p> : null}

      <Row className="mb-5 position-relative">
        <LoadingSpinner loading={hydrantsQuery.loading} />

        <Col>
          <Transfer
            locale={{
              itemUnit: "hydrant",
              itemsUnit: "hydranty",
              notFoundContent: "Lista jest pusta",
              searchPlaceholder: "Wyszukaj"
            }}
            dataSource={hydrantsDataSource}
            showSearch

            filterOption={filterTransferOption}
            targetKeys={targetKeys}
            onChange={handleTransferChange}
            render={(item) => {
              let plans = plansByHydrantId[item.key];
              let plansNumber = plans ? (<Badge pill bg="primary">{plans.length}</Badge>) : null;
              return (
                <div key={item.key}>
                  <span>{item.title}-{item.description} {plansNumber}</span>
                </div>
              );
            }}
            listStyle={{
              width: '100%',
              height: 400,
            }}
          />
        </Col>
        <Col className="relative">
          <Map
            height={'400px'}
            width={'100%'}
            fullscreenControl={false}
            markers={hydrants}
            setCurrentPosition={setCurrentPosition}
            currentPosition={currentPosition}
          />
        </Col>
      </Row>
    </div>
  )
}


