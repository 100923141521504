import React, { useContext } from "react";
import { Formik } from 'formik';
import { Form, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { Link, useParams } from "react-router-dom";
import { RESET_PASSWORD_MUTATION } from '../../common/mutations/user';
import { AuthContext } from "../../context/auth";
import { LoadingButton } from "../UI/LoadingButton/LoadingButton";
import { PublicComponent } from './PublicComponent';

const schema = Yup.object().shape({
  token: Yup.string().required(),
  password: Yup.string().required(),
});


const FormComponent = ({ onCompleted, token }) => {
  const [onSubmit, { loading, error }] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data) => onSubmit({ variables: data })}
      initialValues={{ token }}>
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
      }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Control.Feedback type='invalid'>Pole wymagane</Form.Control.Feedback>
          <div className='mb-3'>
            <Form.Label htmlFor='password'>Hasło</Form.Label>
            <Form.Control
            id='password'
            type='password'
            name='password'
            placeholder='Hasło'
            value={values.password}
            onChange={handleChange}
            isInvalid={touched.password && !!errors.password}
            />
          </div>
          <LoadingButton
            loading={loading}
            disabled={!isValid}
            type="submit">
            Ustaw nowe hasło
          </LoadingButton>
          { error && <Alert variant='danger'>Błąd</Alert> }
        </Form>
      )}
    </Formik>
  );
}

export const ResetPassword = () => {
  const { authorize } = useContext(AuthContext);
  let { token } = useParams();

  return (
    <PublicComponent>
      <div className="formContainer">
        <h2 className="p-2">Ustaw nowe hasło</h2>
        <div className="form-group p-2">
          <FormComponent
            token={token}
            onCompleted={(data) => {authorize(data.resetPassword.token, data.resetPassword.user.admin)}} />
        </div>
        <p>
          <Link to='/login'>
            Zaloguj się
          </Link>
        </p>
      </div>
    </PublicComponent>
  );
}
