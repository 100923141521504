import React, { useContext } from "react";
import { Formik } from 'formik';
import { Form, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { Link } from "react-router-dom";
import { FORGOT_PASSWORD_MUTATION } from '../../common/mutations/user';
import { LoadingButton } from "../UI/LoadingButton/LoadingButton";
import { PublicComponent } from './PublicComponent';

const schema = Yup.object().shape({
  email: Yup.string().required()
});


const FormComponent = () => {
  const [onSubmit, { loading, data={ forgotPassword: { sent: false }}, error }] = useMutation(FORGOT_PASSWORD_MUTATION);

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data) => onSubmit({ variables: data })}
      initialValues={{}}>
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
      }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Control.Feedback type='invalid'>Pole wymagane</Form.Control.Feedback>
          <div className='mb-3'>
            <Form.Label htmlFor='email'>E-mail</Form.Label>
            <Form.Control
              id='email'
              type='email'
              name='email'
              placeholder='E-mail'
              value={values.login}
              onChange={handleChange}
              isInvalid={touched.email && !!errors.email} />
              <Form.Control.Feedback type='invalid'>Błędne pole</Form.Control.Feedback>
          </div>
          <LoadingButton
            loading={loading}
            disabled={!isValid}
            type="submit">
            Wyślij link dla zresetowania hasła
          </LoadingButton>
          { error && <Alert variant='danger'>Błąd</Alert> }
          { data.forgotPassword.sent && <Alert variant="success">Sprawdź swoją skrzynkę</Alert>}
        </Form>
      )}
    </Formik>
  );
}

export const ForgotPassword = () => {

  return (
    <PublicComponent>
      <div className="formContainer">
        <h2 className="p-2">Przywracanie hasła</h2>
        <div className="form-group p-2">
          <FormComponent />
        </div>
        <p>
          <Link to='/login'>
            Zaloguj się
          </Link>
        </p>
      </div>
    </PublicComponent>
  );
}
