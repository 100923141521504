import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { Header } from "./components/UI/Header";
import { JobsContainer } from "./components/ExportInspections/JobsContainer";
import { client } from "./common/ApolloClient";

// Routes
import configureRoutes from './configureRoutes';

// Redux
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from './store';
import reduxStore from './store';

import { Auth } from "./context/auth";

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={reduxStore}>
        <PersistGate loading={null} persistor={persistor}>
          <Auth>
            <BrowserRouter>
              <Header />
              {configureRoutes()}
              <JobsContainer />
            </BrowserRouter>
          </Auth>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
