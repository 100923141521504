import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Form, Modal, Alert } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_MUTATION } from "../../common/mutations/user";
import { LoadingButton } from "../UI/LoadingButton/LoadingButton"
import { LoadingSpinner } from "../UI/LoadingSpinner";
import { AdminComponent } from '../Authorization/AdminComponent';
import { Upload } from 'antd';
import { CloudUpload } from 'react-bootstrap-icons';
import { GET_USER } from '../../common/queries/user';
import * as Yup from 'yup';
import * as moment from 'moment';

const imageTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon"
];

const schema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().optional(),
  passwordConfirmation: Yup.string()
     .oneOf([Yup.ref('password'), null], 'Hasła muszą być takie same')
});

const FormComponent = ({ user, onCompleted }) => {
  const [companyStampImage, setCompanyStampImage] = useState();
  const [employeeStampImage, setEmployeeStampImage] = useState();
  const [companyStampsToDelete, setCompanyStampsToDelete] = useState([]);
  const [employeeStampsToDelete, setEmployeeStampsToDelete] = useState([]);

  const [onSubmit, { loading, error }] = useMutation(UPDATE_MUTATION, {
    onCompleted
  });

  const companyStampsList = user.companyStampImage ? [{
    uid: user.companyStampImage.id,
    name: user.companyStampImage.filename,
    status: 'done',
    url: user.companyStampImage.url,
  }] : [];

  const employeeStampsList = user.employeeStampImage ? [{
    uid: user.employeeStampImage.id,
    name: user.employeeStampImage.filename,
    status: 'done',
    url: user.employeeStampImage.url,
  }] : [];

  const removeCompanyStamp = (data) => {
    setCompanyStampsToDelete(companyStampsToDelete.concat(data.uid));
  }

  const removeEmployeeStamp = (data) => {
    setEmployeeStampsToDelete(employeeStampsToDelete.concat(data.uid));
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data) => {
        onSubmit(
          {
            variables: {
              ...data,
              id: user.id,
              companyStampImage: companyStampImage && companyStampImage.status == "removed" ? null : companyStampImage,
              employeeStampImage: employeeStampImage && employeeStampImage.status == "removed" ? null : employeeStampImage,
              companyStampsToDelete: companyStampsToDelete,
              inspectionDate: data.inspectionDate && data.inspectionDate.length > 0 ? data.inspectionDate : null,
              employeeStampsToDelete: employeeStampsToDelete,
            }
          }
        )
      }}

      initialValues={{
        email: user.email,
        hardware: user.hardware,
        inspectionDate: user.inspectionDate ?  moment(user.inspectionDate).format("YYYY-MM-DD") : null,
        locked: user.locked,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col>
              <Form.Label htmlFor='email'>Email</Form.Label>
                <Form.Control
                  type='text'
                  name='email'
                  placeholder='E-mail'
                  id='email'
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
              <Form.Control.Feedback type='invalid'>Błędny adress E-mail</Form.Control.Feedback>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Check
                name='locked'
                id='locked'
                type="checkbox"
                checked={values.locked}
                label="Konto zablokowane"
                value={values.locked}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Label htmlFor='hardware'>Sprzęt pomiarowy</Form.Label>
                <Form.Control
                  type='text'
                  name='hardware'
                  id='hardware'
                  value={values.hardware}
                  onChange={handleChange}
                />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Label htmlFor='inspectionDate'>Data przeprowadzania inspekcji</Form.Label>
                <Form.Control
                  type='date'
                  name='inspectionDate'
                  id='inspectionDate'
                  value={values.inspectionDate}
                  onChange={handleChange}
                />
            </Col>
          </Row>

          <Col>
            <Form.Label htmlFor='companyStampImage'>Pieczątka firmowa</Form.Label>
            <span style={{display: "none"}}>{user.companyStampImage ? user.companyStampImage.url : ""}</span>
            <Upload
              multiple={false}
              maxCount={1}
              accept={imageTypes}
              listType="picture-card"
              defaultFileList={[...companyStampsList]}
              onChange={(data) => setCompanyStampImage(data.file)}
              onRemove={removeCompanyStamp}
              beforeUpload={() => false}>
              <CloudUpload />
            </Upload>
          </Col>

          <Col>
            <Form.Label htmlFor='employeeStampImage'>Pieczątka Serwisanta</Form.Label>
            <span style={{display: "none"}}>{user.employeeStampImage ? user.employeeStampImage.url : ""}</span>
            <Upload
              multiple={false}
              maxCount={1}
              accept={imageTypes}
              listType="picture-card"
              defaultFileList={[...employeeStampsList]}
              onChange={(data) => {console.log(data); setEmployeeStampImage(data.file)}}
              onRemove={removeEmployeeStamp}
              beforeUpload={() => false}>
              <CloudUpload />
            </Upload>
          </Col>

          <Row className="mb-3">
            <Col>
              <Form.Label htmlFor='password'>Nadaj nowe hasło (opcjonalnie)</Form.Label>
                <Form.Control
                  type='password'
                  name='password'
                  id='password'
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Label htmlFor='passwordConfirmation'>Potwierdź nowe hasło</Form.Label>
                <Form.Control
                  type='password'
                  name='passwordConfirmation'
                  id='passwordConfirmation'
                  onChange={handleChange}
                  isInvalid={!!errors.passwordConfirmation}
                />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <LoadingButton
                type="submit"
                loading={loading}
                disabled={!isValid}>
                Zapisz
              </LoadingButton>
            </Col>
          </Row>
          { error && <Alert variant='danger'>{error.message}</Alert> }
        </Form>
      )}
    </Formik>
  );
}

export const UserEditForm = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const { userId } = useParams();

  useEffect(
    () => {
      let timer1 = setTimeout(() => setShow(true), 100);
      return () => {
        clearTimeout(timer1);
      };
    },
    []
  );

  const { loading, data = { user: {}} } = useQuery(GET_USER, {
    variables: {
      id: parseInt(userId),
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <AdminComponent>
      <Modal show={show} onHide={() => navigate("/users")}>
        <Modal.Header closeButton>
          <Modal.Title>Edytuj użytkownika</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ?
            <LoadingSpinner loading={true} />
            :
            <FormComponent onCompleted={() => navigate("/users")} user={data.user} />
          }
        </Modal.Body>
      </Modal>
    </AdminComponent>
  );
}
