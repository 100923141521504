import React, { useContext } from "react";
import { Formik } from 'formik';
import { Form, Alert, Container, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { Link } from "react-router-dom";
import { LOGIN_MUTATION } from '../../common/mutations/user';
import { AuthContext } from "../../context/auth";
import { LoadingButton } from "../UI/LoadingButton/LoadingButton";
import { PublicComponent } from './PublicComponent';

const schema = Yup.object().shape({
  email: Yup.string().email('Podaj poprawny e-mail').required("E-mail jest wymagany"),
  password: Yup.string().required('Hasło jest wymagane')
});


const FormComponent = ({ onCompleted }) => {
  const [onSubmit, { loading, error }] = useMutation(LOGIN_MUTATION, {
    onCompleted
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data) => onSubmit({ variables: data })}
      initialValues={{}}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Control.Feedback type='invalid'>Pole wymagane</Form.Control.Feedback>
          <div className='mb-3'>
            <Form.Label htmlFor='email'>E-mail</Form.Label>
            <Form.Control
              id='email'
              type='email'
              name='email'
              placeholder='E-mail'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.email && !!errors.email} />
            <Form.Control.Feedback type='invalid'>Błędne pole</Form.Control.Feedback>
          </div>
          <div className='mb-3'>
            <Form.Label htmlFor='password'>Hasło</Form.Label>
            <Form.Control
              id='password'
              type='password'
              name='password'
              placeholder='Hasło'
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.password && !!errors.password}
            />
          </div>
          <LoadingButton
            loading={loading}
            disabled={!isValid}
            type="submit">
            Zaloguj
          </LoadingButton>
          {error && <Alert variant='danger'>Błędny email lub hasło</Alert>}
        </Form>
      )}
    </Formik>
  );
}

export const Login = () => {
  const { authorize } = useContext(AuthContext);

  return (
    <PublicComponent>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs md="6" lg="3">
            <h2>Logowanie</h2>
            <FormComponent onCompleted={(data) => authorize(data.login.token, data.login.user.admin)} />
          </Col>
        </Row>
      </Container>
    </PublicComponent>
  );
}
