import { gql } from "@apollo/client";

export const CREATE_MUTATION =  gql`
  mutation createInspection($hydrantId: ID!, $surface: String!, $marking: String!, $remarks: String) {
    createInspection(input: {
      hydrantId: $hydrantId,
      surface: $surface,
      marking: $marking,
      remarks: $remarks
    }) {
      inspection {
        id
      }
  }
}`;

export const UPDATE_MUTATION =  gql`
  mutation updateInspection(
    $id: ID!, 
    $hydrantId: ID!, 
    $surface: String!, 
    $marking: String!,
    $hydrostaticPressure: Float,
    $hydrodynamicPressure: Float,
    $nominalCapacity: Float,
    $measuringSet: String,
    $meetsRegulationRequirements: Boolean!,
    $hpTechnicallyEfficient: Boolean!,
    $conflict: Boolean!,
    $faultLid: String,
    $faultCap: String,
    $faultMustache: String,
    $faultSpindle: String,
    $faultCase: String,
    $faultValve: String,
    $reasonOfFailureToPerform: String, 
    $remarks: String,
    $startedAt: String,
    $baseImages: [Upload!],
    $images: [Upload!]
    $baseImagesToDelete: [ID!],
    $imagesToDelete: [ID!]

    $brand: String,
    $diameter: String,
    $markingLocation: String,
    $completeness: String,
    $condition: String,
    $paintCoating: String,
    $dehydration: String,
    $bolt: String,
    $boltState: String,
    $hydrantBox: String,
    $boltBox: String,
    $repairWork: String,
    $repairWorkMaterial: String,
    $qualification: String,
    $type: String,
    $state: String,
    $district: String,
    $city: String,
    $street: String,
    $streetNumber: String,
    $location: String,
  ) {
    updateInspection(input: {
      id: $id,
      hydrantId: $hydrantId,
      surface: $surface,
      marking: $marking,
      hydrostaticPressure: $hydrostaticPressure,
      hydrodynamicPressure: $hydrodynamicPressure,
      nominalCapacity: $nominalCapacity,
      measuringSet: $measuringSet,
      meetsRegulationRequirements: $meetsRegulationRequirements,
      hpTechnicallyEfficient: $hpTechnicallyEfficient,
      faultLid: $faultLid,
      faultCap: $faultCap,
      faultMustache: $faultMustache,
      faultSpindle: $faultSpindle,
      faultCase: $faultCase,
      faultValve: $faultValve,
      reasonOfFailureToPerform: $reasonOfFailureToPerform,
      remarks: $remarks,
      baseImages: $baseImages,
      images: $images,
      baseImagesToDelete: $baseImagesToDelete,
      imagesToDelete: $imagesToDelete,
      conflict: $conflict,
      startedAt: $startedAt,
      brand: $brand,
      diameter: $diameter,
      markingLocation: $markingLocation,
      completeness: $completeness,
      condition: $condition,
      paintCoating: $paintCoating,
      dehydration: $dehydration,
      bolt: $bolt,
      boltState: $boltState,
      hydrantBox: $hydrantBox,
      boltBox: $boltBox,
      repairWork: $repairWork,
      repairWorkMaterial: $repairWorkMaterial,
      qualification: $qualification,
      type: $type,
      state: $state,
      district: $district,
      city: $city,
      street: $street,
      streetNumber: $streetNumber,
      location: $location
    }) {
      inspection {
        id
      }
  }
}`;

export const EXPORT_MUTATION =  gql`
  mutation exportInspections($inspectionIds: [String!], $planId: ID, $mode: String, $format: String) {
    exportInspections(input: {
      inspectionIds: $inspectionIds,
      planId: $planId,
      mode: $mode,
      format: $format,
    }) {
      inspectionsExportJob {
        id
        status
        format
      }
  }
}`;