import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Row, Col, Form, Alert, Modal, Button, ProgressBar } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client';
import { PrivateComponent } from '../Authorization/PrivateComponent';
import { SHOW } from '../../common/queries/inspections';
import { LoadingSpinner } from "../UI/LoadingSpinner";
import { ShowInspection } from "../Inspection/ShowInspection";

export const AdminShowInspection = (props) => {
  let [searchParams, setSearchParams] = useSearchParams({ page: 1});
  let page = searchParams.get("page") || 1

  const navigate = useNavigate();
  const { inspectionId, planId, status } = useParams();
  const { loading, data } = useQuery(SHOW, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: parseInt(inspectionId)
    }
  });

  const location = useLocation();
  const handleClose = () => {
    if (location.pathname.startsWith("/admin/inspections")) {
      navigate(`/admin/inspections/${status}?page=${page}`);
    } else {
      navigate(`/admin/plans/${planId}/${data.inspection.status}?page=${page}`);
    }
  }

  return (
    <PrivateComponent>
      {loading ? <LoadingSpinner loading={true} /> :
        <Modal show={true} onHide={handleClose} size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>Inspekcja hydrantu {data.inspection.hydrant.objectCode}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <ShowInspection inspection={data.inspection} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      }
    </PrivateComponent>
  );
}
