import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Form, Alert } from 'react-bootstrap'
import { useMutation } from '@apollo/client';
import { CREATE_MUTATION } from "../../common/mutations/inspection";
import { LoadingButton } from "../UI/LoadingButton/LoadingButton"
import { PrivateComponent } from '../Authorization/PrivateComponent';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  surface: Yup.string().required(),
  marking: Yup.string().required(),
});

const FormComponent = ({ hydrantId, onCompleted }) => {
  const [onSubmit, { loading, error }] = useMutation(CREATE_MUTATION, {
    onCompleted,
    refetchQueries: [
      'hydrant',
      'hydrants'
    ]
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data) => onSubmit({ variables: data })}
      initialValues={{ hydrantId: hydrantId }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Label htmlFor='email'>Nawierzchnia</Form.Label>
                <Form.Select
                  name='surface'
                  id='surface'
                  value={values.surface}
                  onChange={handleChange}
                  isInvalid={touched.surface && !!errors.surface}>
                <option>chodnik asfaltowy</option>
                <option>chodnik betonowy</option>
                <option>trawnik</option>
                <option>śćieżka rowerowa</option>
                <option>jezdnia asfaltowa</option>
                <option>jezdnia brukowa</option>
                <option>jezdnia betonowa</option>
                <option>jezdnia z kostki</option>
                <option>inne</option>
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label htmlFor='email'>Oznakowanie</Form.Label>
                <Form.Select
                  name='marking'
                  id='marking'
                  value={values.marking}
                  onChange={handleChange}
                  isInvalid={touched.marking && !!errors.marking}>
                  <option>tak</option>
                  <option>nie</option>
                  <option>brak HP</option>
                  <option>brak danych</option>
                </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label htmlFor='email'>Uwagi</Form.Label>
                <Form.Control
                  type='text'
                  name='remarks'
                  id='remarks'
                  value={values.remarks}
                  onChange={handleChange}
                  isInvalid={touched.remarks && !!errors.remarks}
                />
              <Form.Control.Feedback type='invalid'>Błędna wartość</Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <LoadingButton
                type="submit"
                loading={loading}
                disabled={!isValid}>
                Dodaj inspeckcję
              </LoadingButton>
            </Col>
          </Row>
          { error && <Alert variant='danger'>{error.message}</Alert> }
        </Form>
      )}
    </Formik>
  );
}

export const CreateInspection = () => {
  const navigate = useNavigate();
  let { hydrantId } = useParams();

  return (
    <PrivateComponent>
      <Row>
        <Col><h4>Utwórz inspekcję</h4></Col>
      </Row>
      <Row>
        <Col>
          <FormComponent
            hydrantId={hydrantId}
            onCompleted={(data) => navigate(`/hydrant/${hydrantId}/inspections`)}/>
        </Col>
      </Row>
    </PrivateComponent>
  );
}
