import React, { useState, useEffect } from "react";
import { useParams,useNavigate, Link, Outlet } from 'react-router-dom'
import { Modal, Spinner, Row, Col } from 'react-bootstrap';
import { useQuery } from "@apollo/client";
import { SHOW } from "../../common/queries/hydrants";

const InspectionsLink = ({ hydrantId, inspectionDate }) => {
  return (
    <Row>
      <Col>
        {inspectionDate && `Ostatnia inspekcja wykonana ${inspectionDate}`}
        {!inspectionDate && "Brak wykonanych inspekcji"}
        <Link to={`/hydrant/${hydrantId}/inspections/new`}>dodaj nową inspekcję</Link> lub <Link to={`/hydrant/${hydrantId}/inspections`}>zobacz listę inspekcji</Link>
      </Col>
    </Row>
  );
}

export const HydrantDetails = () => {
  const { hydrantId } = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  useEffect(
    () => {
      let timer1 = setTimeout(() => setShow(true), 100);
      return () => {
        clearTimeout(timer1);
      };
    },
    []
  );

  const { loading, data = { hydrant: {}} } = useQuery(SHOW, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: parseInt(hydrantId)
    }
  });

  return (
    <Modal show={show} onHide={() => navigate('/')} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title>{`Hydrant numer ${hydrantId}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Spinner animation="grow" />}
        <Row>
          <Col>Ulica: {data.hydrant.street}</Col>
        </Row>
        <Row>
          <Col>
            <InspectionsLink hydrantId={hydrantId} inspectionDate={data.hydrant.lastInspectionAt} />
          </Col>
        </Row>
        <Outlet />
      </Modal.Body>
    </Modal>
  );
}