import React from "react";
import { Row, Col } from 'react-bootstrap'
import moment from "moment";

export const ShowInspection = ({ inspection }) => {
  return (
    <div>
      <Row className="mb-3">
        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Nawierzchnia</h3>
          <p>{inspection.surface}</p>
        </Col>
        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Oznakowanie</h3>
          <p>{inspection.marking}</p>
        </Col>
      </Row>

      {inspection.template === "POZNAN" && <div>
          <h2 className="mb-3 h5">Dane adresowe</h2>
          <Row className="mb-3">
            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Rejon</h3>
              <p>{inspection.state}</p>
            </Col>
            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Gmina</h3>
              <p>{inspection.district}</p>
            </Col>
            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Ulica</h3>
              <p>{inspection.street}</p>
            </Col>
            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Numer posesji</h3>
              <p>{inspection.streetNumber}</p>
            </Col>
          </Row>
        </div>
      }

      <h2 className="mb-3 h5">Wyniki Pomiaru</h2>
      <Row>
        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Ciśnienie hydrostatyczne [MPa]</h3>
          <p>{inspection.hydrostaticPressure}</p>
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Ciśnienie hydrodynamiczne [MPa]</h3>
          <p>{inspection.hydrodynamicPressure}</p>
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Wydajność nominalna [dm³/s]</h3>
          <p>{inspection.nominalCapacity}</p>
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Pomiary wykonano zestawem pomiarowym</h3>
          <p>{inspection.measuringSet}</p>
        </Col>
      </Row>

      <h2 className="mb-3 h5">Wyniki Przeglądu</h2>
      <Row className="mb-4">
        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Spełnia wymagania rozporządzenia</h3>
          <p>{inspection.meetsRegulationRequirements ? "TAK" : "NIE"}</p>
        </Col>
        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">HP sprawny technicznie</h3>
          <p>{inspection.hpTechnicallyEfficient ? "TAK" : "NIE"}</p>
        </Col>
      </Row>

      <h2 className="mb-3 h5">Usterki techniczne</h2>
      <Row>
        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Dekiel</h3>
          <p>{inspection.faultLid}</p>
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Kaptur</h3>
          <p>{inspection.faultCap}</p>
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Wąsy</h3>
          <p>{inspection.faultMustache}</p>
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Wrzeciono</h3>
          <p>{inspection.faultSpindle}</p>
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Skrzynka</h3>
          <p>{inspection.faultCase}</p>
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Zawór</h3>
          <p>{inspection.faultValve}</p>
        </Col>
      </Row>

      {inspection.template === "POZNAN" && <div>
          <h2 className="mb-3 h5">Dane</h2>
          <Row>
            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Rodzaj hydrantu</h3>
              <p>{inspection.type}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Producent</h3>
              <p>{inspection.brand}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Średnica</h3>
              <p>{inspection.diameter}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Kompletność</h3>
              <p>{inspection.completeness}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Stan</h3>
              <p>{inspection.condition}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Stan powłoki malarskiej</h3>
              <p>{inspection.paintCoating}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Odwodnienie hydrantu</h3>
              <p>{inspection.dehydration}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Zasuwa hydrantowa</h3>
              <p>{inspection.bolt}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Stan zasuwy hydrantowej</h3>
              <p>{inspection.boltState}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Skrzynka uliczna hydrantowa</h3>
              <p>{inspection.hydrantBox}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Skrzynka uliczna do zasuwy</h3>
              <p>{inspection.boltBox}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Materiał wykorzystany do naprawy</h3>
              <p>{inspection.repairWorkMaterial}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Kwalifikacja</h3>
              <p>{inspection.qualification}</p>
            </Col>

            <Col xs="12" md="6" className="mb-3">
              <h3 className="inspection-label">Stan</h3>
              <p>{inspection.condition}</p>
            </Col>
          </Row>
        </div>
      }

      <h2 className="mb-3 h5">Uwagi</h2>
      <Row>
        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Inne przyczyny nie wykonania przeglądu HP</h3>
          <p>{inspection.reasonOfFailureToPerform}</p>
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <h3 className="inspection-label">Uwagi</h3>
          <p>{inspection.remarks}</p>
        </Col>
      </Row>

      {inspection.baseImages.length <= 0 ? null : (
        <Row>
          <h3 className="inspection-label">Fotografie początkowe</h3>
          {inspection.baseImages.map((image => { return (
            <Col xs="6" className="mb-2 position-relative">
              <div className="position-absolute bg-dark text-light p-2">
                <div>Wykonano: {inspection.startedAt ? moment(inspection.startedAt).format("DD.MM.YYYY HH:MM:ss") : moment(inspection.finishedAt).subtract(2, 'minutes').format("DD.MM.YYYY HH:MM:ss")} </div>
                <div>(lat: {inspection.latitude}, lng: {inspection.longitude})</div>
              </div>
              <img style={{width: '100%'}} src={image.url}/>
            </Col>
          ) }))}
        </Row>)
      }

      {inspection.images.length <= 0 ? null : (
        <Row>
          <h3 className="inspection-label">Fotografie końcowe</h3>
          {inspection.images.map((image => { return (
            <Col xs="6" className="mb-2 position-relative">
              <div className="position-absolute bg-dark text-light p-2">
                <div>Wykonano: {moment(inspection.finishedAt).format("DD.MM.YYYY HH:MM:ss")} </div>
                <div>(lat: {inspection.latitude}, lng: {inspection.longitude})</div>
              </div>
              <img style={{width: '100%'}} src={image.url}/>
            </Col>
          ) }))}
        </Row>)
      }
    </div >
  );
}