import React, { useState } from "react";
import { Formik } from 'formik';
import { useParams, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Row, Col, Form, Alert, Modal, Button, ProgressBar } from 'react-bootstrap'
import { LoadingButton } from "../UI/LoadingButton/LoadingButton"
import { PrivateComponent } from '../Authorization/PrivateComponent';
import * as Yup from 'yup';
import { LoadingSpinner } from "../UI/LoadingSpinner";
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import { Upload } from 'antd';
import { CloudUpload } from 'react-bootstrap-icons';
import map from "lodash/map";
import compact from "lodash/compact";
import moment from "moment";

//GraphQL
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_MUTATION } from "../../common/mutations/inspection";
import { SHOW } from '../../common/queries/inspections';

const DEFAULT_SURFACE = 'chodnik asfaltowy';
const DEFAULT_MARKING = 'tak';
const DEFAULT_COMPLETNESS = 'tak';
const DEFAULT_MARKING_LOCATION = 'słup';
const DEFAULT_QUALIFICATION = 'konserwacja';
const DEFAULT_DIAMETER = '80';
const DEFAULT_CONDITION = 'czynny';
const DEFAULT_PAINT_COATING = 'dobry';
const DEFAULT_DEHYDRATION = 'sprawne';
const DEFAULT_BOLT = 'prawa';
const DEFAULT_BOLT_STATE = 'sprawna';
const DEFAULT_HYDRANT_BOX = 'sprawna';
const DEFAULT_BOLT_BOX = 'sprawna';
const DEFAULT_REPAIR_WORK = 'tak';
const DEFAULT_REPAIR_WORK_MATERIAL = 'godło';
const DEFAULT_BRAND = 'Akwa';
const DEFAULT_TYPE = 'podziemny';
const DEFAULT_LOCATION = 'skrzyżowanie';
const imageTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon"
];
const DN80 = {
  '0.02': 3.19,
  '0.04': 4.51,
  '0.06': 5.52,
  '0.08': 6.38,
  '0.10': 7.13,
  '0.12': 7.81,
  '0.14': 8.44,
  '0.16': 9.02,
  '0.18': 9.57,
  '0.20': 10.00,
  '0.22': 10.58,
  '0.24': 11.05,
  '0.26': 11.50,
  '0.28': 11.93,
  '0.30': 12.35,
  '0.32': 12.75,
  '0.34': 13.15,
  '0.36': 13.53,
  '0.38': 13.90,
  '0.40': 14.26,
  '0.42': 14.61,
  '0.44': 14.96,
  '0.46': 15.29,
  '0.48': 15.62,
  '0.50': 15.95,
  '0.52': 16.26,
  '0.54': 16.57,
  '0.56': 16.87,
  '0.58': 17.17,
  '0.60': 17.46
};

const schema = Yup.object().shape({
  surface: Yup.string().required(),
  marking: Yup.string().required(),
  remarks: Yup.string().max(2000, "Maksymana ilość znaków to 2000"),
  reasonOfFailureToPerform: Yup.string().max(2000, "Maksymana ilość znaków to 2000"),
});

const handleHydrostaticPressureChange = (handleChange, event, values) => {
  let hydrostaticPressure = parseFloat(event.target.value.replace(/,/g, '.'));
  if (values.hydrodynamicPressure < hydrostaticPressure) {
    values.hydrodynamicPressure = hydrostaticPressure
    values.nominalCapacity = DN80[hydrostaticPressure.toFixed(2)];
  }
  return handleChange(event)
}

const handleHydrodynamicPressureChange = (handleChange, event, values) => {
  values.nominalCapacity = DN80[parseFloat(event.target.value.replace(/,/g, '.')).toFixed(2)];

  return handleChange(event)
}

const FormComponent = ({ inspection, user, hydrantId, onCompleted }) => {
  const [onSubmit, { loading, error }] = useMutation(UPDATE_MUTATION, {
    onCompleted,
    context: { useMultipart: true },
    refetchQueries: [
      'plan'
    ]
  });
  const [step, setStep] = useState(1);
  const [baseImages, setBaseImages] = useState([]);
  const [images, setImages] = useState([]);
  const [baseImagesToDelete, setBaseImagesToDelete] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const startedAt = user.inspectionDate ? moment(user.inspectionDate).hour(moment().hour()).minute(moment().minute()).second(moment().second()).format() : moment().format();
  const allSteps = inspection.template == "POZNAN" ? 9 : 7;

  const removeBaseImage = (data) => {
    setBaseImagesToDelete(baseImagesToDelete.concat(data.uid));
  }

  const removeImage = (data) => {
    setImagesToDelete(baseImagesToDelete.concat(data.uid));
  }

  const imagesFileList = inspection.images.map(function(image) {
    return {
      uid: image.id,
      name: image.filename,
      status: 'done',
      url: image.url,
    }
  });
  const baseImagesFileList = inspection.baseImages.map(function(image) {
    return {
      uid: image.id,
      name: image.filename,
      status: 'done',
      url: image.url,
    }
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data) => {
        console.log("SUBMIT DATA:", { ...data, hydrantId: hydrantId, id: inspection.id });
        onSubmit(
          {
            variables: {
              ...data,
              hydrantId: hydrantId,
              id: inspection.id,
              images: compact(map(images, (image) => image.originFileObj)),
              baseImages: compact(map(baseImages, (image) => image.originFileObj)),
              imagesToDelete: imagesToDelete,
              baseImagesToDelete: baseImagesToDelete,
            }
          }
        )
      }}
      initialValues={{
        surface: inspection.surface || DEFAULT_SURFACE,
        marking: inspection.marking || DEFAULT_MARKING,
        hydrostaticPressure: inspection.hydrostaticPressure || 0,
        hydrodynamicPressure: inspection.hydrodynamicPressure || 0,
        measuringSet: inspection.measuringSet || user.hardware || '',
        meetsRegulationRequirements: inspection.meetsRegulationRequirements || false,
        hpTechnicallyEfficient: inspection.hpTechnicallyEfficient || false,
        nominalCapacity: inspection.nominalCapacity || 0,
        faultLid: inspection.faultLid || '',
        faultCap: inspection.faultCap || '',
        faultMustache: inspection.faultMustache || '',
        faultSpindle: inspection.faultSpindle || '',
        faultCase: inspection.faultCase || '',
        faultValve: inspection.faultValve || '',
        reasonOfFailureToPerform: inspection.reasonOfFailureToPerform || '',
        remarks: inspection.remarks || '',
        hydrantId: hydrantId,
        conflict: inspection.conflict || false,
        brand: inspection.brand || DEFAULT_BRAND,
        diameter: inspection.diameter || DEFAULT_DIAMETER,
        markingLocation : inspection.markingLocation || DEFAULT_MARKING_LOCATION,
        completeness: inspection.completeness || DEFAULT_COMPLETNESS,
        condition: inspection.condition || DEFAULT_CONDITION,
        paintCoating: inspection.paintCoating || DEFAULT_PAINT_COATING,
        dehydration: inspection.dehydration || DEFAULT_DEHYDRATION,
        bolt: inspection.bolt || DEFAULT_BOLT,
        boltState: inspection.boltState || DEFAULT_BOLT_STATE,
        hydrantBox: inspection.hydrantBox || DEFAULT_HYDRANT_BOX,
        boltBox: inspection.boltBox || DEFAULT_BOLT_BOX,
        repairWork: inspection.repairWork || DEFAULT_REPAIR_WORK,
        repairWorkMaterial: inspection.repairWorkMaterial || DEFAULT_REPAIR_WORK_MATERIAL,
        qualification: inspection.qualification || DEFAULT_QUALIFICATION,
        type: inspection.type || DEFAULT_BOLT,
        state: inspection.state || '',
        district: inspection.district || '',
        city: inspection.city || '',
        street: inspection.street || '',
        streetNumber: inspection.streetNumber || '',
        location: inspection.location || DEFAULT_LOCATION,
        startedAt: moment(inspection.startedAt || startedAt).format('YYYY-MM-DD'),
      }}
    >
      {({
        handleSubmit,
        handleChange,
        validateField,
        values,
        touched,
        isValid,
        errors,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <div className="py-3">
              <ProgressBar now={100/allSteps * step} label={`${step}/${allSteps}`} />
            </div>
            {step === 1 && <Row>
              <Col>
                <Form.Label htmlFor='baseImages'>Dodaj maks. 3 zdjęcia początkowe</Form.Label>
                <Upload
                  multiple={true}
                  maxCount={3}
                  accept={imageTypes}
                  listType="picture-card"
                  defaultFileList={[...baseImagesFileList]}
                  onChange={(data) => setBaseImages(data.fileList)}
                  onRemove={removeBaseImage}
                  beforeUpload={() => false}>
                  <CloudUpload />
                </Upload>
              </Col>
            </Row>}
            {step === 2 && inspection.template === "POZNAN" && <div>
              <h2 className="mb-3 h5">Dane adresowe</h2>
              <Row className="mb-4">
                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='state'>Rejon</Form.Label>
                  <Form.Control
                    name='state'
                    id='state'
                    value={values.state}
                    onChange={handleChange}
                    isInvalid={touched.state && !!errors.state}
                  />
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='district'>Gmina</Form.Label>
                  <Form.Control
                    name='district'
                    id='district'
                    value={values.district}
                    onChange={handleChange}
                    isInvalid={touched.district && !!errors.district}
                  />
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='city'>Miejscowość</Form.Label>
                  <Form.Control
                    name='city'
                    id='city'
                    value={values.city}
                    onChange={handleChange}
                    isInvalid={touched.city && !!errors.city}
                  />
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='street'>Ulica</Form.Label>
                  <Form.Control
                    name='street'
                    id='street'
                    value={values.street}
                    onChange={handleChange}
                    isInvalid={touched.street && !!errors.street}
                  />
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='streetNumber'>Numer posesji</Form.Label>
                  <Form.Control
                    name='streetNumber'
                    id='streetNumber'
                    value={values.streetNumber}
                    onChange={handleChange}
                    isInvalid={touched.streetNumber && !!errors.streetNumber}
                  />
                </Col>
              </Row>
            </div>}
            {((step === 3 && inspection.template === "POZNAN") || (step === 2 && inspection.template !== "POZNAN")) && <div>
              <Row className="mb-4">
              <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='location'>Lokalizacja hydrantu</Form.Label>
                  <Form.Select
                    name='location'
                    id='location'
                    value={values.location}
                    onChange={handleChange}
                    isInvalid={touched.location && !!errors.location}>
                    <option>{DEFAULT_LOCATION}</option>
                    <option>ulica</option>
                    <option>chodnik</option>
                    <option>pobocze</option>
                    <option>trawnik</option>
                    <option>teren prywatny</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='surface'>Nawierzchnia</Form.Label>
                  <Form.Select
                    name='surface'
                    id='surface'
                    value={values.surface}
                    onChange={handleChange}
                    isInvalid={touched.surface && !!errors.surface}>
                    <option>{DEFAULT_SURFACE}</option>
                    <option>chodnik betonowy</option>
                    <option>chodnik brukowy</option>
                    <option>chodnik z kostki</option>
                    <option>trawnik</option>
                    <option>śćieżka rowerowa</option>
                    <option>jezdnia asfaltowa</option>
                    <option>jezdnia brukowa</option>
                    <option>jezdnia betonowa</option>
                    <option>jezdnia z kostki</option>
                    <option>droga gruntowa</option>
                    <option>inne</option>
                  </Form.Select>
                </Col>
                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='marking'>Oznakowanie</Form.Label>
                  <Form.Select
                    name='marking'
                    id='marking'
                    value={values.marking}
                    onChange={handleChange}
                    isInvalid={touched.marking && !!errors.marking}>
                    <option>{DEFAULT_MARKING}</option>
                    <option>nie</option>
                    <option>brak HP</option>
                    <option>brak danych</option>
                  </Form.Select>
                </Col>
                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='markingLocation'>Lokalizacja oznakowania</Form.Label>
                  <Form.Select
                    name='markingLocation'
                    id='markingLocation'
                    value={values.markingLocation}
                    onChange={handleChange}
                    isInvalid={touched.markingLocation && !!errors.markingLocation}>
                    <option>{DEFAULT_MARKING_LOCATION}</option>
                    <option>latarnia</option>
                    <option>ogrodzenie</option>
                    <option>elewacja</option>
                    <option>brak</option>
                  </Form.Select>
                </Col>
              </Row>

            </div>}
            {((step === 4 && inspection.template === "POZNAN") || (step === 3 && inspection.template !== "POZNAN")) && <div>
              <h2 className="mb-3 h5">Wyniki Pomiaru</h2>
              <Row>
                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='hydrostaticPressure'>Ciśnienie hydrostatyczne [MPa]</Form.Label>
                  <Form.Control
                    name='hydrostaticPressure'
                    id='hydrostaticPressure'
                    value={values.hydrostaticPressure}
                    onChange={(event) => handleHydrostaticPressureChange(handleChange, event, values)}
                    type='number'
                    min={0}
                    step={0.02}
                    max={0.5}
                    isInvalid={touched.hydrostaticPressure && !!errors.hydrostaticPressure}
                  />
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='hydrodynamicPressure'>Ciśnienie hydrodynamiczne [MPa]</Form.Label>
                  <Form.Control
                    name='hydrodynamicPressure'
                    id='hydrodynamicPressure'
                    value={values.hydrodynamicPressure}
                    min={values.hydrostaticPressure}
                    type='number'
                    step={0.02}
                    max={0.6}
                    onChange={(event) => handleHydrodynamicPressureChange(handleChange, event, values)}

                    isInvalid={touched.hydrodynamicPressure && !!errors.hydrodynamicPressure}
                  />
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='nominalCapacity'>Wydajność nominalna [dm³/s]</Form.Label>
                  <Form.Control
                    disabled
                    name='nominalCapacity'
                    id='nominalCapacity'
                    value={values.nominalCapacity}
                    onChange={handleChange}
                    isInvalid={touched.nominalCapacity && !!errors.nominalCapacity}
                  />
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='measuringSet'>Pomiary wykonano zestawem pomiarowym</Form.Label>
                  <Form.Control
                    name='measuringSet'
                    id='measuringSet'
                    value={values.measuringSet}
                    onChange={handleChange}
                    isInvalid={touched.measuringSet && !!errors.measuringSet}
                  />
                </Col>
              </Row>
            </div>}
            {((step === 5 && inspection.template === "POZNAN") || (step === 4 && inspection.template !== "POZNAN")) && <div>
              <h2 className="mb-3 h5">Wyniki Przeglądu</h2>
              <Row className="mb-4">
                <Col xs="12" md="6" className="mb-3">
                  <Form.Check
                    name='meetsRegulationRequirements'
                    id='meetsRegulationRequirements'
                    type="checkbox"
                    checked={values.meetsRegulationRequirements}
                    label="Spełnia wymagania w/w rozporządzenia"
                    value={values.meetsRegulationRequirements}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs="12" md="6" className="mb-3">
                  <Form.Check
                    name='hpTechnicallyEfficient'
                    id='hpTechnicallyEfficient'
                    type="checkbox"
                    checked={values.hpTechnicallyEfficient}
                    label="HP sprawny technicznie"
                    value={values.hpTechnicallyEfficient}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </div>}
            {((step === 6 && inspection.template === "POZNAN") || (step === 5 && inspection.template !== "POZNAN")) && <div>
              <h2 className="mb-3 h5">Usterki techniczne</h2>
              <Row>
              <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='faultLid'>Dekiel</Form.Label>
                  <Form.Control
                    name='faultLid'
                    id='faultLid'
                    value={values.faultLid}
                    onChange={handleChange}
                  />
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='faultCap'>Kaptur</Form.Label>
                  <Form.Control
                    name='faultCap'
                    id='faultCap'
                    value={values.faultCap}
                    onChange={handleChange}
                  />
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='faultMustache'>Wąsy</Form.Label>
                  <Form.Control
                    name='faultMustache'
                    id='faultMustache'
                    value={values.faultMustache}
                    onChange={handleChange}
                  />
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='faultSpindle'>Wrzeciono</Form.Label>
                  <Form.Control
                    name='faultSpindle'
                    id='faultSpindle'
                    value={values.faultSpindle}
                    onChange={handleChange}
                  />
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='faultCase'>Skrzynka</Form.Label>
                  <Form.Control
                    name='faultCase'
                    id='faultCase'
                    value={values.faultCase}
                    onChange={handleChange}
                  />
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='faultValve'>Zawór</Form.Label>
                  <Form.Control
                    name='faultValve'
                    id='faultValve'
                    value={values.faultValve}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              </div>}
              {(step === 7 && inspection.template === "POZNAN") && <div>
              <h2 className="mb-3 h5">Dane</h2>
              <Row>
                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='type'>Rodzaj hydrantu</Form.Label>
                  <Form.Select
                    name='type'
                    id='type'
                    value={values.type}
                    onChange={handleChange}
                    isInvalid={touched.type && !!errors.type}>
                    <option>{DEFAULT_TYPE}</option>
                    <option>nadziemny</option>
                    <option>kandelaber</option>
                    <option>przerob. Kandelaber</option>
                    <option>wysokowydajny</option>
                    <option>inny</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='brand'>Producent</Form.Label>
                  <Form.Select
                    name='brand'
                    id='brand'
                    value={values.brand}
                    onChange={handleChange}
                    isInvalid={touched.brand && !!errors.brand}>
                    <option>{DEFAULT_BRAND}</option>
                    <option>Hawle</option>
                    <option>Jafar</option>
                    <option>Mittellman</option>
                    <option>inny</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='diameter'>Średnica</Form.Label>
                  <Form.Select
                    name='diameter'
                    id='diameter'
                    value={values.diameter}
                    onChange={handleChange}
                    isInvalid={touched.diameter && !!errors.diameter}>
                    <option>{DEFAULT_DIAMETER}</option>
                    <option>100</option>
                    <option>150</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='completeness'>Kompletność</Form.Label>
                  <Form.Select
                    name='completeness'
                    id='completeness'
                    value={values.completeness}
                    onChange={handleChange}
                    isInvalid={touched.completeness && !!errors.completeness}>
                    <option>{DEFAULT_COMPLETNESS}</option>
                    <option>nie</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='condition'>Stan</Form.Label>
                  <Form.Select
                    name='condition'
                    id='condition'
                    value={values.condition}
                    onChange={handleChange}
                    isInvalid={touched.condition && !!errors.condition}>
                    <option>{DEFAULT_CONDITION}</option>
                    <option>nieczynny</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='paintCoating'>Stan powłoki malarskiej</Form.Label>
                  <Form.Select
                    name='paintCoating'
                    id='paintCoating'
                    value={values.paintCoating}
                    onChange={handleChange}
                    isInvalid={touched.paintCoating && !!errors.paintCoating}>
                    <option>{DEFAULT_PAINT_COATING}</option>
                    <option>zły</option>
                    <option>nie dotyczy</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='dehydration'>Odwodnienie hydrantu</Form.Label>
                  <Form.Select
                    name='dehydration'
                    id='dehydration'
                    value={values.dehydration}
                    onChange={handleChange}
                    isInvalid={touched.dehydration && !!errors.dehydration}>
                    <option>{DEFAULT_DEHYDRATION}</option>
                    <option>niesprawne</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='bolt'>Zasuwa hydrantowa</Form.Label>
                  <Form.Select
                    name='bolt'
                    id='bolt'
                    value={values.bolt}
                    onChange={handleChange}
                    isInvalid={touched.bolt && !!errors.bolt}>
                    <option>{DEFAULT_BOLT}</option>
                    <option>lewa</option>
                    <option>brak</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='boltState'>Stan zasuwy hydrantowej</Form.Label>
                  <Form.Select
                    name='boltState'
                    id='boltState'
                    value={values.boltState}
                    onChange={handleChange}
                    isInvalid={touched.boltState && !!errors.boltState}>
                    <option>{DEFAULT_BOLT_STATE}</option>
                    <option>niesprawna</option>
                    <option>brak</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='hydrantBox'>Skrzynka uliczna hydrantowa</Form.Label>
                  <Form.Select
                    name='hydrantBox'
                    id='hydrantBox'
                    value={values.hydrantBox}
                    onChange={handleChange}
                    isInvalid={touched.hydrantBox && !!errors.hydrantBox}>
                    <option>{DEFAULT_HYDRANT_BOX}</option>
                    <option>niesprawna</option>
                    <option>brak</option>
                    <option>nie dotyczy</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='boltBox'>Skrzynka uliczna do zasuwy</Form.Label>
                  <Form.Select
                    name='boltBox'
                    id='boltBox'
                    value={values.boltBox}
                    onChange={handleChange}
                    isInvalid={touched.boltBox && !!errors.boltBox}>
                    <option>{DEFAULT_BOLT_BOX}</option>
                    <option>niesprawna</option>
                    <option>brak</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='repairWork'>Prace Naprawcze</Form.Label>
                  <Form.Select
                    name='repairWork'
                    id='repairWork'
                    value={values.repairWork}
                    onChange={handleChange}
                    isInvalid={touched.repairWork && !!errors.repairWork}>
                    <option>{DEFAULT_REPAIR_WORK}</option>
                    <option>nie</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='repairWorkMaterial'>Materiał wykorzystany do naprawy</Form.Label>
                  <Form.Select
                    name='repairWorkMaterial'
                    id='repairWorkMaterial'
                    value={values.repairWorkMaterial}
                    onChange={handleChange}
                    isInvalid={touched.repairWorkMaterial && !!errors.repairWorkMaterial}>
                    <option>{DEFAULT_REPAIR_WORK_MATERIAL}</option>
                    <option>kły</option>
                    <option>numerki</option>
                    <option>tabliczki</option>
                    <option>zaślepki</option>
                  </Form.Select>
                </Col>

                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='qualification'>Kwalifikacja</Form.Label>
                  <Form.Select
                    name='qualification'
                    id='qualification'
                    value={values.qualification}
                    onChange={handleChange}
                    isInvalid={touched.qualification && !!errors.qualification}>
                    <option>{DEFAULT_QUALIFICATION}</option>
                    <option>naprawa</option>
                    <option>wymiana</option>
                    <option>bez uwag</option>
                  </Form.Select>
                </Col>
              </Row>
              </div>}
              {((step === 8 && inspection.template === "POZNAN") || (step === 6 && inspection.template !== "POZNAN")) && <Row>
                <Col>
                  <Form.Label htmlFor='images'>Dodaj maks. 3 zdjęcie końcowe</Form.Label>
                  <Upload
                    multiple={true}
                    maxCount={3}
                    accept={imageTypes}
                    listType="picture-card"
                    defaultFileList={[...imagesFileList]}
                    onChange={(data) => setImages(data.fileList)}
                    onRemove={removeImage}
                    beforeUpload={() => false}>
                    <CloudUpload />
                  </Upload>
                </Col>
              </Row>}
            {((step === 9 && inspection.template === "POZNAN") || (step === 7 && inspection.template !== "POZNAN")) && <div>
              <Row>
                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='reasonOfFailureToPerform'>Inne przyczyny nie wykonania przeglądu HP</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name='reasonOfFailureToPerform'
                    id='reasonOfFailureToPerform'
                    value={values.reasonOfFailureToPerform}
                    onChange={(e) => {validateField('reasonOfFailureToPerform'); handleChange(e)}}
                  />
                  {errors.reasonOfFailureToPerform && <div className="text-danger">{errors.reasonOfFailureToPerform}</div>}
                </Col>
                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='remarks'>Uwagi</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name='remarks'
                    id='remarks'
                    value={values.remarks}
                    onChange={(e) => {validateField('remarks'); handleChange(e)}}
                  />
                  {errors.remarks && <div className="text-danger">{errors.remarks}</div>}
                </Col>
                <Col xs="12" md="6" className="mb-3">
                  <Form.Check
                    name='conflict'
                    id='conflict'
                    type="checkbox"
                    checked={values.conflict}
                    label="Brak możliwości przeprowadzenia inspekcji"
                    value={values.conflict}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs="12" md="6" className="mb-3">
                  <Form.Label htmlFor='startedAt'>Data przeprowadzania inspekcji</Form.Label>
                  <Form.Control
                    name='startedAt'
                    as='input'
                    type='date'
                    id='startedAt'
                    value={values.startedAt}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </div>}

            <Row className="mt-3">
              <Col className="d-flex justify-content-between">
                <Button
                  disabled={step == 1}
                  onClick={() => setStep(step - 1)} >
                  <ArrowLeft /> Wstecz
                </Button>
                {step == allSteps && (
                  <LoadingButton
                    type="submit"
                    loading={loading}
                    disabled={!isValid}>
                    Zapisz inspeckcję
                  </LoadingButton>
                )}
                {step != allSteps && (
                  <Button
                    disabled={step == allSteps}
                    onClick={() => setStep(step + 1)} >
                    Dalej <ArrowRight />
                  </Button>
                )}
              </Col>
            </Row>

            {error && <Alert variant='danger'>{error.message}</Alert>}
          </Form>
        )
      }}
    </Formik>
  );
}

export const EditInspection = () => {
  let [searchParams, setSearchParams] = useSearchParams({ page: 1});
  const location = useLocation();
  let page = searchParams.get("page") || 1
  const navigate = useNavigate();
  const { planId, inspectionId, status } = useParams();
  const { loading, data } = useQuery(SHOW, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: parseInt(inspectionId)
    }
  });

  const handleClose = () => {
    if (location.pathname.startsWith("/admin/inspections")) {
      navigate(`/admin/inspections/${status}?page=${page}`);
    } else if (location.pathname.startsWith("/admin/plans")) {
      navigate(`/admin/plans/${planId}/${data.inspection.status}?page=${page}`);
    } else {
      navigate(`/plans/${planId}?page=${page}`);
    }
  }

  return (
    <PrivateComponent>
      {loading ? (<LoadingSpinner loading={true} />) : (
        !data?.inspection ? <Modal show={true} onHide={handleClose} size='xl'><Modal.Header closeButton><Modal.Title>"Nie można załadować inspekcji"</Modal.Title></Modal.Header></Modal>
         :
        <Modal show={true} onHide={handleClose} size='xl' backdrop='static'>
          <Modal.Header closeButton>
            <Modal.Title>Inspekcja hydrantu {data.inspection.hydrant.objectCode}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="d-flex justify-content-end">
                <a href={`https://www.google.pl/maps/place/${data.inspection.latitude},${data.inspection.longitude}`} target="_blank">Prowadź do punktu</a>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormComponent
                  hydrantId={parseInt(data.inspection.hydrant.id)}
                  inspection={data.inspection}
                  user={data.user}
                  onCompleted={handleClose} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>)
      }
    </PrivateComponent>
  );
}
