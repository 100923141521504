import React, { useState } from "react";
import { Link, useSearchParams, useParams, NavLink, Outlet } from "react-router-dom";
import { Row, Col, Button as BootstrapButton, Nav, DropdownButton, Dropdown  } from "react-bootstrap";
import { PencilFill, Download } from 'react-bootstrap-icons';
import { Table, DatePicker, Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { statusMap } from "../../common/statuses";
// GraphQL
import { useQuery, useMutation } from "@apollo/client";
import { SHOW } from "../../common/queries/plans";
import { EXPORT_MUTATION } from "../../common/mutations/inspection";

// Redux
import { useDispatch } from 'react-redux'
import {
  addJob
} from '../../reducers/exportJobsReducer';

const { RangePicker } = DatePicker;

export const AdminPlan = () => {
  const [filters, setFilters] = useState({});
  const [sorting, setSorting] = useState({
    field: "finishedAt",
    order: "descend",
  });

  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");

  const [dateRange, setDateRange] = useState([moment().startOf('year'), moment().endOf('month')]);
  const [selectedInspectionIds, setSelectedInspectionIds] = useState([]);

  let [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  let page = searchParams.get("page") || 1

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchedColumn(dataIndex);
              setSearchText(selectedKeys[0]);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  });

  const { planId, status } = useParams();

  const dispatch = useDispatch();

  const handleExportJobStarted = (exportData) => {
    const job = exportData.exportInspections.inspectionsExportJob;
    dispatch(addJob({
      id: job.id,
      status: job.status,
    }));
  }

  const dateFormat = 'DD.MM.YYYY';
  const dbDateFormat = 'YYYY-MM-DD';

  const [onSubmitExport, exportMutation] = useMutation(EXPORT_MUTATION, {onCompleted: handleExportJobStarted});

  const planQuery = useQuery(SHOW, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: planId,
      page: parseInt(page),
      filters: filters,
      sorting: sorting,
      status: status,
      startDate: dateRange[0].format(dbDateFormat),
      endDate: dateRange[1].format(dbDateFormat),
    }
  });
  const data = planQuery.data;
  const plan = data?.plan || {};
  const inspections = plan.inspections || [];

  const inspectionLink = (status, id) => {
    return `/admin/plans/${planId}/${status}/show/${id}?page=${page}`;
  }


  const inspectionLinkEdit = (status, id) => {
    return `/admin/plans/${planId}/${status}/edit/${id}?page=${page}`;
  }
  const columns = [
    {
      title: 'Wykonawca',
      dataIndex: 'email',
      render: (_text, record) => record.user?.email,
      filteredValue: filters.email,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Hydrant',
      dataIndex: 'objectCode',
      render: (_text, record) => record.hydrant.objectCode,
      filteredValue: filters.objectCode,
      ...getColumnSearchProps('objectCode'),
    },
    {
      title: 'Data rozpoczenia inspekcji',
      dataIndex: 'startedAt',
      sortOrder: sorting.field == 'startedAt' && sorting.order,
      sorter: true,
      render: (text, _record) => text && moment(text).format("DD.MM.YYYY"),
    },
    {
      title: 'Data zakończenia inspekcji',
      dataIndex: 'finishedAt',
      sortOrder: sorting.field == 'finishedAt' && sorting.order,
      sorter: true,
      render: (text, _record) => text && moment(text).format("DD.MM.YYYY"),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_text, record) => statusMap[record.status]
    },
    {
      title: 'Wyeksportowana',
      dataIndex: 'exported',
      filters: [
        {
          text: 'Tak',
          value: true,
        },
        {
          text: 'Nie',
          value: false,
        },
      ],
      filteredValue: filters.exported,
      render: (_text, record) => record.exported ? (<span>TAK (<a href={record.pdfFileUrl} target="_blank">Zobacz</a>)</span>) : "NIE",
    },
    {
      title: 'Akcje',
      render: (_text, record) => <span>
          <NavLink className="nav-link" to={inspectionLink(record.status, record.id)}>Pokaż inspekcję</NavLink>
          <NavLink className="nav-link" to={inspectionLinkEdit(record.status, record.id)}>Edytuj inspekcję</NavLink>
        </span>,
    },
  ];

  const handleRangePickerChange = (dates, dateStrings) => {
    setDateRange(dates);
  }

  const handleExportPDFClick = () => {
    if (selectedInspectionIds.length == 0) {
      return alert("Proszę wybrać chociaż jedną inspekcję do wyeksportowania");
    }
    onSubmitExport({ variables: {inspectionIds: selectedInspectionIds} })
  }

  const handleExportXlsClick = (mode) => {
    if (mode == 'selected' && selectedInspectionIds.length == 0) {
      return alert("Proszę wybrać chociaż jedną inspekcję do wyeksportowania");
    }

    onSubmitExport({ variables: {inspectionIds: selectedInspectionIds, mode, format: 'xls', planId: plan.id} })
  }

  const onChange = (pagination, filters, sorter, extra) => {
    setFilters(filters);
    setSorting({
      field: sorter.field,
      order: sorter.order,
    });
    console.log('params', pagination, filters, sorter, extra);
  }

  const planEditLink = (planId) => {
    return `/admin/plans/${planId}/edit`;
  }

  const planShowLink = (planId, status) => {
    return `/admin/plans/${planId}/${status}`;
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedInspectionIds(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const selectionType = "checkbox";

  return (
    <div>
      <Row className="mb-2">
        <Col><h4>Inspekcje planu "{plan.name}" z {moment(plan.date).format("DD.MM.YYYY")}</h4></Col>
        <Col className="d-flex justify-content-end">
          <Link to={planEditLink(plan.id)}>
            <Button variant="primary" size="sm">
              <PencilFill /> Edytuj plan
            </Button>
          </Link>
        </Col>
      </Row>

      <Nav variant="tabs" className="mb-3">
        <Nav.Item>
          <NavLink className="nav-link" to={planShowLink(plan.id, "TO_DO")}>Do zrobienia</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink className="nav-link" to={planShowLink(plan.id, "DONE")}>Zrobione</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink className="nav-link" to={planShowLink(plan.id, "CONFLICT")}>Konflikt</NavLink>
        </Nav.Item>
      </Nav>

      <Row className="mb-3">
        <Col className="d-flex align-items-end">
          <Button variant="primary" size="sm" onClick={handleExportPDFClick} disabled={selectedInspectionIds.length == 0} >
            <Download /> Exportuj do PDF
          </Button>

          <DropdownButton size="sm" className="mx-2" title="Eksportuj do Excel">
            <Dropdown.Item onClick={() => handleExportXlsClick('selected')}>Wybrane</Dropdown.Item>
            <Dropdown.Item onClick={() => handleExportXlsClick('all')}>Wszystkie w tym planie</Dropdown.Item>
            <Dropdown.Item onClick={() => handleExportXlsClick('all-done')}>Wszystkie zrobione w tym planie</Dropdown.Item>
            <Dropdown.Item onClick={() => handleExportXlsClick('all-todo')}>Wszystkie do zrobienia w tym planie</Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col className="d-flex justify-content-end py-2">
          <RangePicker
            defaultValue={[dateRange[0], dateRange[1]]}
            format={dateFormat}
            onChange={handleRangePickerChange}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            columns={columns}
            dataSource={inspections}
            rowKey={inspection => inspection.id}
            onChange={onChange}
            pagination={{
              total: plan?.inspectionsTotal,
              onChange: (page) => setSearchParams({ page }),
              current: parseInt(page),
              defaultPageSize: data?.perPage,
              pageSize: data?.perPage,
              showSizeChanger: false
            }} />
        </Col>
      </Row>

      <Row>
        <Col>
          <Outlet />
        </Col>
      </Row>
    </div>
  );
}
