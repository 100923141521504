import React, { useState } from "react";
import { Formik } from 'formik';
import { Outlet } from 'react-router-dom'
import { Form, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { IMPORT_MUTATION } from '../../common/mutations/hydrants';
import { LoadingButton } from "../UI/LoadingButton/LoadingButton";
import HydrantsImportJobDetails from "../Hydrants/HydrantsImportJobDetails";
import { ImportHydrants } from "./AdminPlanForm/ImportHydrants";

const FormComponent = () => {
  const [file, setFile] = useState(null);

  const [onSubmit, { loading, data = { importHydrants: { importHydrantsJob: { status: null, importError: null } } }, error }] = useMutation(IMPORT_MUTATION);

  const handleFileSelected = (data) => {
    if (data.fileList.length > 0) {
      setFile(data.file);
    }
  };

  const handleFileRemoved = () => {
    setFile(null);
  }

  return (
    <Formik
      onSubmit={(data) => onSubmit({ variables: {...data, file }})}
      initialValues={{}}>
      {({
        handleSubmit,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Control.Feedback type='invalid'>Pole wymagane</Form.Control.Feedback>
          <HydrantsImportJobDetails importHydrantsJob={data.importHydrants.importHydrantsJob} />

          <Row>
            <Col>
              <ImportHydrants
                onChange={handleFileSelected}
                onRemove={handleFileRemoved}
              />
            </Col>
          </Row>

          <LoadingButton
            loading={loading}
            disabled={!file}
            type="submit">
            Importuj hydranty
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
}

export const AdminImportHydrants = () => {

  return (
    <FormComponent />
  );
}
