import { gql } from "@apollo/client";

export const LOGIN_MUTATION =  gql`
  mutation login($email: String!, $password: String!) {
    login(input: {
      email: $email,
      password: $password
    }) {
      token
      user {
        admin
        locked
      }
    }
  }`;


export const INVITE_MUTATION =  gql`
  mutation inviteUser($email: String!, $password: String, $passwordConfirmation: String, $hardware: String) {
    inviteUser(input: {
      email: $email,
      password: $password,
      passwordConfirmation: $passwordConfirmation,
      hardware: $hardware
    }) {
      user {
        id
        email
      }
    }
  }
`;

export const CONFIRM_MUTATION =  gql`
  mutation confirmUser($token: String!, $password: String!) {
    confirmUser(input: {
      token: $token,
      password: $password
    }) {
      token
      user {
        admin
      }
    }
  }
`;

export const UPDATE_MUTATION = gql`
  mutation updateUser(
    $id: ID!,
    $email: String!,
    $locked: Boolean,
    $hardware: String,
    $inspectionDate: String,
    $companyStampImage: Upload,
    $employeeStampImage: Upload,
    $companyStampsToDelete: [ID!],
    $employeeStampsToDelete: [ID!]
  ) {
    updateUser(input: {
      id: $id,
      email: $email,
      locked: $locked,
      hardware: $hardware,
      inspectionDate: $inspectionDate,
      companyStampImage: $companyStampImage,
      employeeStampImage: $employeeStampImage,
      companyStampsToDelete: $companyStampsToDelete,
      employeeStampsToDelete: $employeeStampsToDelete
    }) {
      user {
        id
        email
        hardware
        locked
      }
    }
  }
`

export const CHANGE_SETTINGS_MUTATION = gql`
  mutation changeUserSettings(
    $inspectionDate: String,
    $hardware: String,
  ) {
    changeUserSettings(input: {
      inspectionDate: $inspectionDate,
      hardware: $hardware
    }) {
      user {
        inspectionDate
        hardware
      }
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION =  gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(input: {
      email: $email
    }) {
      sent
    }
  }
`;

export const RESET_PASSWORD_MUTATION =  gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(input: {
      token: $token,
      password: $password
    }) {
      token
      user {
        admin
      }
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION =  gql`
  mutation changePassword($currentPassword: String!, $password: String!) {
    changePassword(input: {
      currentPassword: $currentPassword,
      password: $password
    }) {
      changed
    }
  }
`;
