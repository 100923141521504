import React, { useContext } from "react";
import { Formik } from 'formik';
import { Form, Alert, Container, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD_MUTATION } from '../../common/mutations/user';
import { LoadingButton } from "../UI/LoadingButton/LoadingButton";
import { PrivateComponent } from "./PrivateComponent";

const schema = Yup.object().shape({
  currentPassword: Yup.string().required("Obecne hasło jest wymagane"),
  password: Yup.string().required("Nowe hasło jest wymagane"),
});


const FormComponent = ({ onCompleted, token }) => {
  const [onSubmit, { loading, data = { changePassword: { changed: false }}, error }] = useMutation(CHANGE_PASSWORD_MUTATION, {
    onCompleted
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data) => onSubmit({ variables: data })}
      initialValues={{ token }}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
      <Form onSubmit={handleSubmit}>
        <Form.Control.Feedback type='invalid'>Pole wymagane</Form.Control.Feedback>
          <Row>
            <Col>
              <Form.Label htmlFor='currentPassword'>Obecne hasło</Form.Label>
              <Form.Control
                id='currentPassword'
                type='password'
                name='currentPassword'
                placeholder='Obecne hasło'
                value={values.currentPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.currentPassword && !!errors.currentPassword}
              />
              <Form.Control.Feedback type='invalid'>{errors.currentPassword}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label htmlFor='password'>Nowe hasło</Form.Label>
              <Form.Control
                id='password'
                type='password'
                name='password'
                placeholder='Nowe hasło'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.password && !!errors.password}
              />
              <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <LoadingButton
                loading={loading}
                disabled={!isValid}
                type="submit">
                  Zmień hasło
              </LoadingButton>
            </Col>
          </Row>
          { error && <Alert variant="danger">Błąd</Alert> }
          { data.changePassword.changed && <Alert variant="success">Hasło zostało zmienione</Alert>}
        </Form>
      )}
    </Formik>
  );
}

export const ChangePassword = () => {

  return (
    <PrivateComponent>
      <Container className='mt-5'>
        <Row>
          <Col><h4>Ustaw nowe hasło</h4></Col>
        </Row>
        <Row>
          <Col>
            <FormComponent />
          </Col>
        </Row>
      </Container>
    </PrivateComponent>
  );
}
