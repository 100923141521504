import React from "react";
import { Spinner } from "react-bootstrap";

export const LoadingSpinner = (props) => {
  const size = props.size || 100;
  const variant = props.variant || "primary";
  const animation = props.animation || "border";

  if (props.loading) {
    return (
      <Spinner 
        animation={animation}
        variant={variant}
        className={"position-absolute top-50 start-50"}
        style={{
          zIndex:1000, 
          width: size + "px", 
          height: size + "px", 
          border: (size/10) + "px solid", 
          borderRight: "10px solid transparent"
        }} 
      />
    );
  }

  return null;
}