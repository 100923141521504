import React from "react";
import { Outlet } from "react-router-dom";
import { PrivateComponent } from "../../components/Authorization/PrivateComponent";
import { Container } from "react-bootstrap";

export const ContractorPlansPage = () => {
  return (
    <PrivateComponent>
      <Container className='mt-5'>
        <Outlet />
      </Container>
    </PrivateComponent>
  )
}
