import React, { useEffect } from "react";
import { Container, Row, Col, Button, ListGroup } from "react-bootstrap";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { INDEX } from "../../common/queries/user";
import { PrivateComponent } from '../Authorization/PrivateComponent';
import { PersonPlus } from 'react-bootstrap-icons';
import * as moment from 'moment';

export const UsersList = () => {
  const { refetch, data = { users: []} } = useQuery(INDEX, {
    fetchPolicy: 'cache-and-network'
  });

  const editUserLink = (userId) => {
    return `/users/${userId}/edit`
  }

  let location = useLocation();
  useEffect(
    refetch,
    [location]
  )
  return (
    <PrivateComponent>
      <Container className='mt-5'>
        <Row className="mb-5">
          <Col><h4>Lista użytkowników</h4></Col>
          <Col className="d-flex justify-content-end">
            <Link to="/users/invite">
              <Button variant="primary" size="sm" >
                <PersonPlus/> Dodaj użytkownika
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListGroup variant="flush">
              {data.users.map(user =>
                <ListGroup.Item key={user.email} className="d-flex">
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">{user.email}</div>
                    <p>Przypisany sprzęt pomiarowy: {user.hardware ? user.hardware : "-"}</p>
                    <p>Data inspekcji: {user.inspectionDate ? moment(user.inspectionDate).format("YYYY-MM-DD") : "-"}</p>
                  </div>
                  <Link to={editUserLink(user.id)} >Edytuj</Link>

                </ListGroup.Item>
              )}
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <Outlet />
    </PrivateComponent>
  );
}
