import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { CalendarPlus } from 'react-bootstrap-icons';
import { Table } from 'antd';
import moment from 'moment'

import { useQuery } from "@apollo/client";
import { INDEX as PLANS_INDEX } from "../../common/queries/plans";

export const AdminPlans = () => {
  let [searchParams, setSearchParams] = useSearchParams({ page: 1});
  let page = searchParams.get("page") || 1

  const { loading, data = { plansTotal: 0, perPage: 1, plans: []} } = useQuery(PLANS_INDEX, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: parseInt(page),
    }
  });

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend'],
      render: (_text, record) => <Link to={planEditLink(record.id)}>{record.name || "Plan"}</Link>
    },
    {
      title: 'Data',
      dataIndex: 'date',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.date - b.date,
      render: (text, _record) => moment(text).format("DD.MM.YYYY"),
    },
    {
      title: 'Liczba hydrantów',
      dataIndex: 'hydrantsTotal'
    }
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }

  const planEditLink = (planId) => {
    return `/admin/plans/${planId}/TO_DO`;
  }

  return (
    <div>
      <Row className="mb-2">
        <Col><h4>Plany</h4></Col>
        <Col className="d-flex justify-content-end">
          <Link to='/admin/plans/new'>
            <Button variant="primary" size="sm" >
              <CalendarPlus /> Utwórz plan
            </Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table
            columns={columns}
            dataSource={data.plans}
            rowKey={plan => plan.id}
            onChange={onChange}
            pagination={{
              total: data.plansTotal,
              onChange: (page) => setSearchParams({ page }),
              current: parseInt(page),
              defaultPageSize: data.perPage,
              pageSize: data.perPage,
              showSizeChanger: false
            }} />
        </Col>
      </Row>
    </div>
  );
}
