import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import {useMutation, useQuery} from '@apollo/client';
import { ADD_MUTATION } from '../../common/mutations/hydrants';
import { LoadingButton } from "../UI/LoadingButton/LoadingButton";
import { INDEX as PLANS_INDEX } from "../../common/queries/plans";
import { useLocation, useNavigate } from "react-router-dom";

const useQueryParams = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const schema = Yup.object().shape({
  cityName: Yup.string().required('Pole wymagane'),
  objectCode: Yup.string().required('Pole wymagane'),
  planId: Yup.string().required('Pole wymagane'),
  district: Yup.string().required('Pole wymagane'),
  street: Yup.string().required('Pole wymagane'),
  latitude: Yup.string().required('Pole wymagane'),
  longitude: Yup.string().required('Pole wymagane'),
});
const FormComponent = () => {
  const navigate = useNavigate();
  const query = useQueryParams();

  const [onSubmit, { loading, data = { addHydrant: { } }}] = useMutation(ADD_MUTATION, {
    onCompleted: (data) => {
      navigate(`/plans/${data.createHydrant.inspection.planId}/inspections/${data.createHydrant.inspection.id}/edit`);
    }
  });
  const [location, setLocation] = useState({ latitude: '54', longitude: '18' });
  const plansQuery = useQuery(PLANS_INDEX, {
    fetchPolicy: 'network'
  })
  const plans = plansQuery && plansQuery.data && plansQuery.data.plans || [];

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLocation({ latitude: position.coords.latitude.toString(), longitude: position.coords.longitude.toString() });
    });
  });

  return (
    <Formik
      validationSchema={schema}
      enableReinitialize
      onSubmit={(data) => onSubmit({ variables: data})}
      initialValues={{
        latitude: location.latitude,
        longitude: location.longitude,
        planId: query.get('planId') || plans[0] && plans[0].id
      }}>
      {({
          handleSubmit,
          handleChange,
          values,
          touched,
          isValid,
          handleBlur,
          errors,
        }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Control.Feedback type='invalid'>Pole wymagane</Form.Control.Feedback>
            <Row className="mb-3 position-relative">
              <Col>
                <Form.Label htmlFor='name'>Miasto</Form.Label>
                <Form.Control
                  id='cityName'
                  name='cityName'
                  placeholder='Warszawa - Mokotów'
                  value={values.cityName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.cityName && !!errors.cityName} />
                <Form.Control.Feedback type='invalid'>{errors.cityName}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-3 position-relative">
            <Col>
              <Form.Label htmlFor='name'>Kod Obiektu</Form.Label>
              <Form.Control
                id='objectCode'
                name='objectCode'
                value={values.objectCode}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.objectCode && !!errors.objectCode} />
              <Form.Control.Feedback type='invalid'>{errors.objectCode}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-3 position-relative">
            <Col>
              <Form.Label htmlFor='name'>Numer Planu</Form.Label>
              <Form.Select
                name='planId'
                id='planId'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.planId}
              >
                {
                  plans.map((plan) => (
                    <option value={plan.id} key={plan.id}>{plan.name}</option>
                  ))
                }
              </Form.Select>
              <Form.Control.Feedback type='invalid'>{errors.planId}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-3 position-relative">
            <Col>
              <Form.Label htmlFor='name'>Dzielnica</Form.Label>
              <Form.Control
                id='district'
                name='district'
                value={values.district}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.district && !!errors.district} />
              <Form.Control.Feedback type='invalid'>{errors.district}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-3 position-relative">
            <Col>
              <Form.Label htmlFor='name'>Ulica</Form.Label>
              <Form.Control
                id='street'
                name='street'
                value={values.street}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.street && !!errors.street} />
              <Form.Control.Feedback type='invalid'>{errors.street}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-3 position-relative">
            <Col>
              <Form.Label htmlFor='name'>Szerokosc geograficzna</Form.Label>
              <Form.Control
                id='latitude'
                name='latitude'
                value={values.latitude}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.latitude && !!errors.latitude} />
              <Form.Control.Feedback type='invalid'>{errors.latitude}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-3 position-relative">
            <Col>
              <Form.Label htmlFor='name'>Długość geograficzna</Form.Label>
              <Form.Control
                id='longitude'
                name='longitude'
                value={values.longitude}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.longitude && !!errors.longitude} />
              <Form.Control.Feedback type='invalid'>{errors.longitude}</Form.Control.Feedback>
            </Col>
          </Row>
          <LoadingButton
            loading={loading}
            disabled={!isValid}
            type="submit">
            Utwórz hydrant
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
}

export const AdminAddHydrant = () => {
  return (
    <FormComponent />
  );
}
