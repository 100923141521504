import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";

import * as Yup from 'yup';
import { Form, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { LoadingButton } from "../UI/LoadingButton/LoadingButton";

import 'antd/dist/antd.css';

import { useMutation } from "@apollo/client";

import { CREATE_MUTATION, CREATE_FROM_FILE_MUTATION } from "../../common/mutations/plan";
import { PlanDatepicker } from "./AdminPlanForm/PlanDatepicker";
import { HydrantTransfer } from "./AdminPlanForm/HydrantsTransfer";
import { ImportHydrants } from "./AdminPlanForm/ImportHydrants";

const schema = Yup.object().shape({
  name: Yup.string().required('Podaj nazwę planu'),
});

const FormComponent = ({ onCompleted }) => {
  const [file, setFile] = useState(null);
  const [currentTab, setCurrentTab] = useState('map');

  const [selectedDate, setSelectedDate] = useState();
  const [targetKeys, setTargetKeys] = useState([]);

  const handleFileSelected = (data) => {
    if (data.fileList.length > 0) {
      setFile(data.file);
    }
  };

  const handleFileRemoved = () => {
    setFile(null);
  }

  const handleDateChange = (date, dateString) => {
    setTargetKeys([]);
    setSelectedDate(dateString);
  }

  const handleHydrantsChange = (hydrantIds) => {
    setTargetKeys(hydrantIds);
  }

  const [onCreate, createQuery] = useMutation(CREATE_MUTATION, {
    onCompleted,
  });
  const [onCreateFromFile, createFromFileQuery] = useMutation(CREATE_FROM_FILE_MUTATION, {
    onCompleted,
  });
  
  const handlePreviewError = (e) => {
    console.log(e, 'error in file-viewer');
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data) => { 
        if (currentTab == "map") {
          onCreate({ variables: { name: data.name, hydrantIds: targetKeys, date: selectedDate } });
        } else {
          onCreateFromFile({ variables: { name: data.name, file, date: selectedDate } });
        }
      }}
      initialValues={{}}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3 position-relative">
            <Col>
              <Form.Label htmlFor='name'>Nazwa</Form.Label>
              <Form.Control
                id='name'
                name='name'
                placeholder='Warszawa - Mokotów'
                value={values.name}
                onChange={handleChange}
                isInvalid={!!errors.name} />
              <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
            </Col>
          </Row>

          <PlanDatepicker onDateChange={handleDateChange} selectedDate={selectedDate} />

          {
            !selectedDate ? null :
              <Tabs activeKey={currentTab} onSelect={(k) => setCurrentTab(k)} className="mb-3">
                <Tab eventKey="map" title="Wybierz z mapy">
                  <HydrantTransfer onHydrantsChange={handleHydrantsChange} date={selectedDate} />
                </Tab>
                <Tab eventKey="file" title="Zaimportuj z pliku">
                  <Row>
                    <Col>
                      <ImportHydrants 
                        onChange={handleFileSelected}
                        onRemove={handleFileRemoved}
                      />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
          }

          <Row className="mb-5">
            <Col className="d-flex justify-content-end">
              <LoadingButton
                loading={createQuery?.loading || createFromFileQuery?.loading}
                disabled={
                  !selectedDate || !values.name
                }
                type="submit">
                Zapisz plan
              </LoadingButton>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export const AdminPlanNewForm = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Row>
        <Col><h4>Nowy plan</h4></Col>
      </Row>

      <Row>
        <FormComponent onCompleted={(data) => navigate(`/admin/plans`)} />
      </Row>
    </div>
  );
}
