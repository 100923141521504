import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';


const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.message === 'Bad credentials') {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = '/login';
      }
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

export const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(createUploadLink(
    {
      uri: process.env.REACT_APP_GRAPHQL_URL || '/graphql'
    }
  )),
  cache: new InMemoryCache(),
});

