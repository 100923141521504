import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { INDEX } from "../../common/queries/plans";
import { Table } from 'antd';
import moment from 'moment';

const columns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    render: (_text, record) => <Link to={`/plans/${record.id}`}>{record.name || "Plan"}</Link>
  },
  {
    title: 'Do realizacji dnia',
    dataIndex: 'date',
    render: (text, record) => (<span>{moment(record.date).format("DD.MM.YYYY")}</span>),
  },
  {
    title: 'Liczba hydrantów',
    dataIndex: 'hydrantsTotal'
  }
];

export const PlansList = () => {
  let [searchParams, setSearchParams] = useSearchParams({ page: 1});
  let page = searchParams.get("page") || 1
  const { loading, data = { plansTotal: 0, perPage: 1, plans: []} } = useQuery(INDEX, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: parseInt(page)
    }
  });

  return (
    <div className="pb-3">
      <Row>
        <Col><h4>Lista planów</h4></Col>
      </Row>
      <Row>
        <Col>
          { loading &&  <Spinner animation="grow" /> }
          <Table
            columns={columns}
            dataSource={data.plans}
            pagination={{
              total: data.plansTotal,
              onChange: (page) => setSearchParams({ page }),
              current: parseInt(page),
              defaultPageSize: data.perPage,
              pageSize: data.perPage,
              showSizeChanger: false
            }}
          />
        </Col>
      </Row>
    </div>
  );
}