import React, { useContext } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { Person } from 'react-bootstrap-icons';
import { AuthContext } from "../../context/auth";

export const NavBar = (props) => {
  const personDropdownTitle = (<Person size={20} />);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const onLogoutClick = () => {
    logout();
    navigate("/login");
  }

  console.log("NavBar");

  return (
    <Nav className="justify-content-end py-2" variant="pills">
      <Nav.Item>
        <NavLink className="nav-link" to="/">Mapa</NavLink>
      </Nav.Item>
      <Nav.Item>
          <NavLink className="nav-link" to="/plans">Plany</NavLink>
      </Nav.Item>
      <NavDropdown title={personDropdownTitle} id="collasible-nav-dropdown">
        <NavDropdown.Item href="/profile">{props.data.user.email}</NavDropdown.Item>
        <NavDropdown.Item href="/change_password">Zmień hasło</NavDropdown.Item>
        <NavDropdown.Item href="/change_settings">Zmień ustawienia</NavDropdown.Item>
        <NavDropdown.Item onClick={onLogoutClick}>Wyloguj</NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
};
