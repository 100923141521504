import React from 'react';
import { ToastContainer } from 'react-bootstrap';
import { JobToast } from './JobToast';

// Redux
import { useSelector } from 'react-redux'
import {
  selectJobs,
} from '../../reducers/exportJobsReducer';

import { isAuthenticated, canAccessAdminUI } from "../../context/auth";

// Channels
// import ExportJobsChannel from 'channels/export_jobs_channel';

export const JobsContainer = (props) => {
  const jobs = useSelector(selectJobs);
  
  const toasts = Object.keys(jobs).map((jobId) => {
    return (<JobToast job={jobs[jobId]} key={jobId} />);
  })

  if (!isAuthenticated() || !canAccessAdminUI()) { return null }

  return (
    <ToastContainer className="p-3" position={"bottom-end"}>
      {toasts}
    </ToastContainer>
  );
};
