import React, { useState } from 'react';
import { INDEX_FOR_MONTH as PLANS_INDEX } from "../../../common/queries/plans";
import { DatePicker } from 'antd';
import moment from 'moment';
import { useQuery } from "@apollo/client";
import { Form, Spinner, Badge, Col, Row } from 'react-bootstrap';
import _ from "lodash";

export const PlanDatepicker = (props) => {
  const [currentMonth, setCurrentMonth] = useState(moment().format("YYYY-MM"));
  const plansQuery = useQuery(PLANS_INDEX, {
    fetchPolicy: 'cache-and-network',
    variables: {
      yearAndMonth: currentMonth,
    }
  });
  const plans = plansQuery.data && plansQuery.data.plans || [];
  const plansCountByDate = _.countBy(plans, (plan) => moment(plan.date).format("YYYY-MM-DD"));

  const handlePanelChange = (value, mode) => {
    setCurrentMonth(value.format("YYYY-MM"));
  }

  const handleDateChange = (date, dateString) => {
    props.onDateChange(date, dateString);
  }

  return (
    <Row className="mb-5 position-relative">
      <Col xs={6} md={2}>
        <Form.Label htmlFor='email'>Data</Form.Label>
        <div>
          <DatePicker
            onChange={(date, dateString) => { handleDateChange(date, dateString) }}
            onPanelChange={handlePanelChange}
            renderExtraFooter={mode => {
              return (
                !plansQuery.loading ? null :
                  <div className="position-relative">
                    <Spinner animation="border" size="sm" /> Ładuję plany...
                  </div>
              )
            }}
            dateRender={current => {
              return (
                <div className="ant-picker-cell-inner">
                  {current.date()}
                  {plansCountByDate[current.format("YYYY-MM-DD")] ? <Badge pill bg="primary" style={{ position: "absolute", bottom: "0", padding: "2px 4px" }} >{plansCountByDate[current.format("YYYY-MM-DD")]}</Badge> : null}
                </div>
              );
            }}
          />
        </div>
      </Col>
      {/* <Col>
        { !props.selectedDate || !plansCountByDate[moment(props.selectedDate).format("YYYY-MM-DD")] ? null : 
          <div style={{marginTop: "35px"}}>Zobacz plany utworzone dla tej daty</div>
        }
      </Col> */}
    </Row>
  )
}