import React, { useContext } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom'; 
import { Person } from 'react-bootstrap-icons';
import { AuthContext } from "../../context/auth";

export const AdminNavBar = (props) => {
  const personDropdownTitle = (<Person size={20} />);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const onLogoutClick = () => {
    logout();
    navigate("/login");
  }

  console.log("AdminNavBar");

  return (
    <Nav className="justify-content-end py-2" variant="pills">
      <Nav.Item>
        <NavLink className="nav-link" to="/">Mapa</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className="nav-link" to="/admin/hydrants">Hydranty</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className="nav-link" to="/admin/inspections/TO_DO">Inspekcje</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className="nav-link" to="/admin/plans">Plany</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className="nav-link" to="/plans">Plany widok wykonawcy</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className="nav-link" to="/users">Użytkownicy</NavLink>
      </Nav.Item>
      <NavDropdown title={personDropdownTitle} id="collasible-nav-dropdown">
        <NavDropdown.Item href="/profile">{props.data.user.email}</NavDropdown.Item>
        <NavDropdown.Item href="/change_password">Zmień hasło</NavDropdown.Item>
        <NavDropdown.Item onClick={onLogoutClick}>Wyloguj</NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
};