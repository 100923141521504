import React from "react";

import { Routes, Route } from "react-router-dom";
import { Index } from "./components/Hydrants/Index";
import { Login } from "./components/Authorization/Login";
import { Confirm } from "./components/Authorization/Confirm";
import { ForgotPassword } from "./components/Authorization/ForgotPassword";
import { ResetPassword } from "./components/Authorization/ResetPassword";
import { UsersList } from "./components/Users/UsersList";
import { UserInvite } from "./components/Users/UserInvite";
import { UserEditForm } from "./components/Users/UserEditForm";
import { ChangePassword } from "./components/Authorization/ChangePassword";
import { ChangeSettings } from "./components/User/ChangeSettings";
import { CreateInspection } from "./components/Inspection/CreateInspection";

import { AdminHydrantsPage } from "./pages/Admin/AdminHydrantsPage";
import { AdminImportHydrants } from "./components/Admin/AdminImportHydrants";
import { AdminAddHydrant } from "./components/Admin/AdminAddHydrant";
import { HydrantsList } from "./components/Hydrants/HydrantsList";

import { AdminInspectionsPage } from "./pages/Admin/AdminInspectionsPage";
import { AdminInspections } from "./components/Admin/AdminInspections";
import { AdminShowInspection } from "./components/Admin/AdminShowInspection";
import { EditInspection as AdminEditInspection } from "./components/Inspection/EditInspection";

import { AdminPlansPage } from "./pages/Admin/AdminPlansPage";
import { AdminPlans } from "./components/Admin/AdminPlans";
import { AdminPlan } from "./components/Admin/AdminPlan";
import { AdminPlanNewForm } from "./components/Admin/AdminPlanNewForm";
import { AdminPlanEditForm } from "./components/Admin/AdminPlanEditForm";

import { HydrantDetails } from "./components/Hydrants/HydrantDetails";
import { HydrantInspectionsList } from "./components/Hydrants/HydrantInspectionsList";

import { ContractorPlansPage } from "./pages/Contractor/ContractorPlansPage";
import { PlansList } from "./components/Plans/PlansList";
import { PlanDetails } from "./components/Plans/PlanDetails";
import { EditInspection } from "./components/Inspection/EditInspection";

export default () => {
  return (
    <Routes>
      <Route path="admin/hydrants" element={<AdminHydrantsPage />}>
        <Route index element={<HydrantsList />} />
        <Route path="import" element={<AdminImportHydrants />} />
        <Route path="new" element={<AdminAddHydrant />} />
      </Route>
      <Route exact path="/admin/inspections" element={<AdminInspectionsPage />} >
        <Route index element={<AdminInspections />}/>
        <Route path=":status" element={<AdminInspections />}>
          <Route path="show/:inspectionId" element={<AdminShowInspection />}/>
          <Route path="edit/:inspectionId" element={<AdminEditInspection />}/>
        </Route>
      </Route>
      <Route exact path="/admin/plans" element={<AdminPlansPage />}>
        <Route index element={<AdminPlans />} />
        <Route path="new" element={<AdminPlanNewForm />} />
        <Route path=":planId/edit" element={<AdminPlanEditForm />}></Route>
        <Route path=":planId/:status" element={<AdminPlan />}>
          <Route path="show/:inspectionId" element={<AdminShowInspection />}></Route>
          <Route path="edit/:inspectionId" element={<AdminEditInspection />}></Route>
        </Route>
      </Route>
      <Route exact path="/plans" element={<ContractorPlansPage />}>
        <Route index element={<PlansList />} />
        <Route path=":planId" element={<PlanDetails />}>
          <Route path="inspections/:inspectionId/edit" element={<EditInspection />} />
        </Route>
      </Route>
      <Route path="/users/" element={<UsersList />}>
        <Route path=":userId/edit" element={<UserEditForm />}></Route>
        <Route path="invite" element={<UserInvite />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      {/* <Route path="/reset_password/:token" element={<ResetPassword />} /> */}
      <Route path="/change_password" element={<ChangePassword />} />
      <Route path="/change_settings" element={<ChangeSettings />} />
      {/* <Route path="/confirm/:token" element={<Confirm />} /> */}
      <Route path="/" element={<Index />}>
        <Route path="/hydrant/:hydrantId" element={<HydrantDetails />}>
          <Route path="inspections" element={<HydrantInspectionsList />} />
          <Route path="inspections/new" element={<CreateInspection />} />
        </Route>
      </Route>
    </Routes>
  );
}
