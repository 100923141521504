import { gql } from "@apollo/client";

export const INDEX = gql`
  query plans($userId: String, $page: Int){
    plansTotal
    perPage
    plans(
      userId: $userId,
      page: $page
    ) {
      id
      name
      date
      hydrantsTotal
    }
  }
`;

export const INDEX_FOR_MONTH = gql`
  query plans($yearAndMonth: String){
    plans(
      yearAndMonth: $yearAndMonth,
    ) {
      date
    }
  }
`;

export const INDEX_WITH_HYDRANTS = gql`
  query plans($page: Int){
    plansTotal
    perPage
    plans(
      page: $page
    ) {
      id
      name
      date
      user {
        email
      }
      hydrants {
        district
        objectCode
      }
    }
  }
`;

export const INDEX_WITH_HYDRANT_IDS = gql`
  query plans($date: String!){
    plans(
      date: $date
    ) {
      id
      name
      hydrants {
        id
      }
    }
  }
`;

// TODO: add start and end dates
export const SHOW = gql`
  query plan(
    $id: ID!,
    $page: Int,
    $status: String,
    $searchPhrase: String,
    $startDate: String,
    $endDate: String,
    $latitude: String,
    $longitude: String,
    $filters: InspectionFilter,
    $sorting: Sorting
  ){
    perPage
    plan(
      id: $id
    ) {
      id
      name
      date
      inspectionsTotal (
        status: $status,
        searchPhrase: $searchPhrase,
        filters: $filters
      )
      user {
        id
        email
      }
      mapInspections(latitude: $latitude, longitude: $longitude, distance: 1, filters: $filters) {
        id
        planId
        latitude
        longitude
        specialType
        status
        done
      }
      inspections(
        page: $page,
        status: $status,
        startDate: $startDate,
        endDate: $endDate,
        searchPhrase: $searchPhrase,
        filters: $filters,
        sorting: $sorting
      ) {
        id
        planId
        latitude
        longitude
        specialType
        done
        status
        exported
        finishedAt
        startedAt
        pdfFileUrl
        user {
          email
        }
        hydrant {
          id
          objectCode
          district
        }
      }
    }
  }
`;
