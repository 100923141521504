import React, { useContext, useEffect, useState } from 'react';
import { Toast, Row, Col } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

// GraphQL
import { useQuery } from "@apollo/client";
import { SHOW } from "../../common/queries/inspections_export_jobs";

// Redux
import { useDispatch } from 'react-redux'
import {
  deleteJob, updateJob
} from '../../reducers/exportJobsReducer';

export const JobToast = (props) => {
  const { data, loading, startPolling, stopPolling } = useQuery(SHOW, {
    fetchPolicy: 'no-cache',
    variables: {
      id: props.job.id
    } 
  });
  
  const status = data && data.inspectionsExportJob && data.inspectionsExportJob.status
  
  React.useEffect(() => {
    if (status !== 'completed') startPolling(2000);
    return () => stopPolling();
  }, [status])

  const percentage = props.job.progress || 0;

  const message = status == "completed" ? `Inspekcje wyeskportowane do ${props.job.format}` : `Trwa eksport inspekcji do ${props.job.format}`;
  const link = status == "completed" ? (<a href={props.job.zipFileUrl} download>Pobierz</a>) : null;

  const dispatch = useDispatch();

  // const [loadJob, { called, loading, data }] = useLazyQuery(SHOW, {
  //   fetchPolicy: 'no-cache',
  //   variables: {
  //     id: props.job.id
  //   } 
  // });

  // if (status && status != "completed" && status != "error" && !loading) {
  //   setTimeout(() => {
  //     loadJob();
  //   }, 1500);    
  // }

  const inspectionsExportJob = data?.inspectionsExportJob || {};
    
  if (inspectionsExportJob.id && (props.job.progress != inspectionsExportJob.progress || props.job.status != inspectionsExportJob.status)) {
    dispatch(updateJob(inspectionsExportJob));
  }

  const handleCloseClick = () => {
    dispatch(deleteJob(props.job));
  }

  return (
    <Toast onClose={handleCloseClick}>
      <Toast.Header style={{backgroundColor: "#4e4e4e", color: "#fff"}}>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto">Eksport inspekcji</strong>
      </Toast.Header>
      <Toast.Body>
        <Row>
          <Col xs="2">
            <div style={{ width: 40, height: 40 }}>
              <CircularProgressbar strokeWidth={12} value={percentage} text={`${percentage}%`} />
            </div>
          </Col>
          <Col>
            <div>{message}</div>
            <div>{link}</div>
          </Col>
        </Row>
      </Toast.Body>
    </Toast>
  );
};
