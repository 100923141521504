import React from "react";
import { Button, Spinner } from 'react-bootstrap';

export const LoadingButton = ({ loading, disabled, children, ...buttonProps }) => {
  return(
    <Button disabled={disabled || loading} {...buttonProps}>
      {loading && <Spinner animation="border" size="sm" role="status" aria-hidden="true" />}
      {children}
    </Button>
  );
}