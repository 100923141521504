import L from 'leaflet';

const iconCompleted = new L.Icon({
  iconUrl: '/marker-todo.svg',
  iconRetinaUrl: '/marker-todo.svg',
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(40, 50),
  className: 'leaflet-div-icon'
});

const iconMe = new L.Icon({
  iconUrl: '/marker-me.svg',
  iconRetinaUrl: '/marker-me.svg',
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(40, 50),
  className: 'leaflet-div-icon'
});

const iconToDo = new L.Icon({
  iconUrl: '/marker-completed.svg',
  iconRetinaUrl: '/marker-completed.svg',
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(40, 50),
  className: 'leaflet-div-icon'
});

const iconToDoSpecial = new L.Icon({
  iconUrl: '/marker-todo-special.svg',
  iconRetinaUrl: '/marker-todo-special.svg',
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(40, 50),
  className: 'leaflet-div-icon'
});


const iconConflict = new L.Icon({
  iconUrl: '/marker-conflict.svg',
  iconRetinaUrl: '/marker-conflict.svg',
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(40, 50),
  className: 'leaflet-div-icon'
});

export { iconCompleted, iconToDo, iconMe, iconConflict, iconToDoSpecial };
