import { gql } from "@apollo/client";

export const INDEX = gql`
  query inspections($page: Int, $status: String, $sorting: Sorting, $filters: InspectionFilter, $searchPhrase: String, $startDate: String, $endDate: String){
    inspectionsTotal (
      status: $status,
      searchPhrase: $searchPhrase,
      startDate: $startDate,
      endDate: $endDate,
      filters: $filters,
    )
    perPage
    limit
    inspections(
      page: $page,
      status: $status,
      filters: $filters,
      sorting: $sorting,
      searchPhrase: $searchPhrase,
      startDate: $startDate,
      endDate: $endDate,
    ) {
    id
    planId
    latitude
    longitude
    specialType
    done
    finishedAt
    startedAt
    status
    exported
    pdfFileUrl
    user {
      email
    }
    hydrant {
      id
      objectCode
      district
    }
  }

  }
`;

export const SHOW = gql`
  query inspection($id: ID!){
    user {
      hardware
      inspectionDate
    }
    inspection(
      id: $id
    ) {
      id
      planId
      latitude
      longitude
      surface
      marking
      hydrostaticPressure
      hydrodynamicPressure
      measuringSet
      meetsRegulationRequirements
      hpTechnicallyEfficient
      faultLid
      faultCap
      faultMustache
      faultSpindle
      faultCase
      faultValve
      nominalCapacity
      reasonOfFailureToPerform
      remarks
      done
      status
      conflict
      finishedAt
      startedAt

      brand
      diameter
      markingLocation
      completeness
      condition
      paintCoating
      dehydration
      bolt
      boltState
      hydrantBox
      boltBox
      repairWork
      repairWorkMaterial
      qualification
      type
      state
      district
      city
      street
      streetNumber
      location

      template

      images {
        id
        url
        filename
      }
      baseImages {
        id
        url
        filename
      }
      hydrant {
        id
        objectCode
        district
      }
    }
  }
`;

      // presignedAwsHeaders {
      //   policy
      //   credential
      //   algorithm
      //   date
      //   signature
      // }
