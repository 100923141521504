import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Modal, Alert } from 'react-bootstrap'
import { useMutation } from '@apollo/client';
import { INVITE_MUTATION } from "../../common/mutations/user";
import { LoadingButton } from "../UI/LoadingButton/LoadingButton"
import { PrivateComponent } from '../Authorization/PrivateComponent';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  email: Yup.string().email('Podaj poprawny e-mail').required("E-mail jest wymagany"),
  password: Yup.string().required('Hasło jest wymagane'),
  passwordConfirmation: Yup.string()
     .oneOf([Yup.ref('password'), null], 'Hasła muszą się pokrywać')
});

const FormComponent = ({ onCompleted }) => {
  const [onSubmit, { loading, error }] = useMutation(INVITE_MUTATION, {
    onCompleted
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data) => onSubmit({ variables: data })}
      initialValues={{}}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Label htmlFor='email'>Email</Form.Label>
                <Form.Control
                  type='text'
                  name='email'
                  placeholder='E-mail'
                  id='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                />
              <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label htmlFor='password'>Hasło</Form.Label>
              <Form.Control
                id='password'
                type='password'
                name='password'
                placeholder='Hasło'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label htmlFor='passwordConfirmation'>Powtórz hasło</Form.Label>
              <Form.Control
                id='passwordConfirmation'
                type='password'
                name='passwordConfirmation'
                placeholder='Powtórz hasło'
                onBlur={handleBlur}
                value={values.passwordConfirmation}
                onChange={handleChange}
                isInvalid={touched.passwordConfirmation && !!errors.passwordConfirmation}
                />
                <Form.Control.Feedback type='invalid'>{errors.passwordConfirmation}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label htmlFor='hardware'>Sprzęt pomiarowy</Form.Label>
                <Form.Control
                  type='text'
                  name='hardware'
                  placeholder='Sprzęt pomiarowy'
                  id='hardware'
                  value={values.hardware}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.hardware && !!errors.hardware}
                />
              <Form.Control.Feedback type='invalid'>Błędny sprzęt pomiarowy</Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <LoadingButton
                type="submit"
                loading={loading}
                disabled={!isValid}>
                Dodaj użytkownika
              </LoadingButton>
            </Col>
          </Row>
          { error && <Alert variant='danger'>{error.message}</Alert> }
        </Form>
      )}
    </Formik>
  );
}

export const UserInvite = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  useEffect(
    () => {
      let timer1 = setTimeout(() => setShow(true), 100);
      return () => {
        clearTimeout(timer1);
      };
    },
    []
  );

  return (
    <PrivateComponent>
      <Modal show={show} onHide={() => navigate("/users")}>
        <Modal.Header closeButton>
          <Modal.Title>Dodaj nowego użytkownika</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormComponent onCompleted={() => navigate("/users")}/>
        </Modal.Body>
      </Modal>
    </PrivateComponent>
  );
}
