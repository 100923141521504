import React, { useState, useEffect } from "react";
import { Formik } from 'formik';
import { useNavigate, useParams } from "react-router-dom";

import * as Yup from 'yup';
import { Form, Row, Col } from 'react-bootstrap';
import { LoadingButton } from "../UI/LoadingButton/LoadingButton";
import { LoadingSpinner } from "../UI/LoadingSpinner";

import 'antd/dist/antd.css';

import { useMutation, useQuery } from "@apollo/client";

import { SHOW as SHOW_PLAN } from "../../common/queries/plans";
import { UPDATE_MUTATION } from "../../common/mutations/plan";
import { HydrantTransfer } from "./AdminPlanForm/HydrantsTransfer"

import moment from 'moment';

const FormComponent = ({ plan, onCompleted }) => {
  const [targetKeys, setTargetKeys] = useState([]);
  
  const handleHydrantsChange = (hydrantIds) => {
    setTargetKeys(hydrantIds);
  }

  const plansHydrants = plan.inspections?.map(inspection => inspection.hydrant) || [];
  const plansHydrantsIds = plansHydrants.map(hydrant => hydrant.id);

  const [onSubmit, { loading, error }] = useMutation(UPDATE_MUTATION, {
    onCompleted,
  });

  const schema = Yup.object().shape({
    name: Yup.string().required('Podaj nazwę planu'),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(data) => { onSubmit({ variables: { id: plan.id, name: data.name, hydrantIds: targetKeys, date: plan.date } }) }}
      initialValues={{
        name: plan.name,
      }}>
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3 position-relative">
            <Col>
              <Form.Label htmlFor='name'>Nazwa</Form.Label>
              <Form.Control
                id='name'
                name='name'
                placeholder='Warszawa - Mokotów'
                value={values.name}
                onChange={handleChange}
                isInvalid={!!errors.name} />
              <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
            </Col>
          </Row>

          <HydrantTransfer onHydrantsChange={handleHydrantsChange} date={plan.date} plan={plan} />

          <Row className="mb-5">
            <Col className="d-flex justify-content-end">
              <LoadingButton
                loading={false}
                disabled={targetKeys.length == 0 && plansHydrantsIds.length == 0}
                type="submit">
                Zapisz plan
              </LoadingButton>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export const AdminPlanEditForm = () => {
  const navigate = useNavigate();
  const { planId } = useParams();
  const { loading, data = { plan: { inspectionsTotal: 1, inspections: [] } } } = useQuery(SHOW_PLAN, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: parseInt(planId)
    }
  });

  return (
    <div>
      {loading ? <LoadingSpinner loading={true} /> :
        <>
          <Row>
            <h4>Edytuj Plan "{data.plan.name}" z {moment(data.plan.date).format("DD.MM.YYYY")}</h4>
            <p>Uwaga, usunięcie hydrantu z planu usunie powiązaną inspekcję!</p>
          </Row>

          <Row>
            <FormComponent plan={data.plan} onCompleted={(data) => navigate(`/admin/plans`)} />
          </Row>
        </>
      }
    </div>
  );
}
