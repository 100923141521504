import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AdminComponent } from "../../components/Authorization/AdminComponent";
import { Row, Col, Container, Button } from "react-bootstrap";
import {CloudUploadFill, PlusCircle} from 'react-bootstrap-icons';

export const AdminHydrantsPage = () => {
  const navigate = useNavigate();
  const goToImportHydrants = () => navigate('/admin/hydrants/import');
  const goToAddHydrant = () => navigate('/admin/hydrants/new');

  return (
    <AdminComponent>
      <Container className='mt-5'>
        <Row>
          <Col><h4>Hydranty</h4></Col>
          <Col className="d-flex justify-content-end">
            <Button variant="primary" size="sm" onClick={goToAddHydrant} >
              <PlusCircle/> Dodaj hydrant
            </Button>
            <Button variant="primary" size="sm" onClick={goToImportHydrants} >
              <CloudUploadFill/> Importuj hydranty
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </AdminComponent>
  );
}
