import { gql } from "@apollo/client";

export const IMPORT_MUTATION =  gql`
  mutation importHydrants($file: Upload!) {
    importHydrants(input: {
      file: $file
    }) {
      importHydrantsJob {
        status
        importError
        hydrantsImportJobsErrors {
          message
          rowNumber
        }
      }
  }
}`;

export const ADD_MUTATION = gql`
   mutation createHydrant($cityName: String!, $objectCode: String!, $planId: String!, $district: String!, $street: String!, $latitude: String!, $longitude: String!) {
    createHydrant(input: {
      cityName: $cityName,
      objectCode: $objectCode,
      planId: $planId,
      district: $district,
      street: $street,
      latitude: $latitude,
      longitude: $longitude,
    }) {
       inspection {
         id
         planId
       }
  }
}`;