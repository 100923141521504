import { gql } from "@apollo/client";

export const CREATE_MUTATION =  gql`
  mutation createPlan($name: String!, $date: String!, $hydrantIds: [String!]!) {
    createPlan(input: {
      name: $name,
      date: $date,
      hydrantIds: $hydrantIds
    }) {
      plan {
        id
        name
        date
      }
  }
}`;

export const CREATE_FROM_FILE_MUTATION =  gql`
  mutation createPlanFromFile($name: String!, $date: String!, $file: Upload!) {
    createPlanFromFile(input: {
      name: $name,
      date: $date,
      file: $file
    }) {
      plan {
        id
        name
        date
      }
  }
}`;


export const UPDATE_MUTATION =  gql`
  mutation updatePlan($id: ID!, $name: String!, $date: String!, $hydrantIds: [String!]!) {
    updatePlan(input: {
      id: $id,
      name: $name,
      date: $date,
      hydrantIds: $hydrantIds
    }) {
      plan {
        id
        name
        date
      }
  }
}`;
