import { gql } from "@apollo/client";

export const GET_USER = gql`
  query user($id: ID!) {
    user(
      id: $id
    ) {
      id
      email
      hardware
      inspectionDate
      locked
      companyStampImage {
        id
        url
        filename
      }
      employeeStampImage {
        id
        url
        filename
      }
    }
  }
`;

export const INDEX = gql`
  query users{
    users {
      id
      email
      hardware
      inspectionDate
    }
  }
`;

export const CURRENT = gql`
  query user {
    user {
      id email admin locked hardware inspectionDate
    }
  }
`;
