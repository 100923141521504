import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";
import { AdminNavBar } from '../Admin/AdminNavBar';
import { NavBar } from './NavBar';
import { useQuery } from '@apollo/client';
import { CURRENT } from '../../common/queries/user';
import { AuthContext, isAuthenticated } from "../../context/auth";

export const Header = () => {
  const { canAccessAdminUI } = useContext(AuthContext);
  const { loading, data = { user: {}} } = useQuery(CURRENT, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Spinner animation="grow" />;
  } 

  if (!isAuthenticated()) {
    return null;
  }
  
  return (
    <div>
      {canAccessAdminUI ? <AdminNavBar data={data} /> : <NavBar data={data} />}
    </div>
  )
}

