import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import { INDEX } from "../../common/queries/hydrants";
import { PrivateComponent } from '../Authorization/PrivateComponent';
import { Table } from 'antd';

const columns = [
  {
    title: 'Numer hydrantu',
    dataIndex: 'objectCode',
  },
  {
    title: 'Okręg',
    dataIndex: 'district',
  },
  {
    title: 'Ostatnia inspekcja',
    dataIndex: 'lastInspectionAt',
  },
];

const onChange = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra);
}

export const HydrantsList = () => {
  let [searchParams, setSearchParams] = useSearchParams({ page: 1});
  let page = searchParams.get("page") || 1

  const { loading, previousData, data = previousData } = useQuery(INDEX, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page: parseInt(page)
    }
  });

  return (
    <PrivateComponent>
      <Row className='mt-5'>
        <Col className="position-relative">
          { loading && <Spinner animation="grow" className="position-absolute top-50 start-50" style={{zIndex:1000}} /> }
          <Table
            columns={columns}
            dataSource={data?.hydrants}
            pagination={{
              total: data?.hydrantsTotal,
              onChange: (page) => setSearchParams({ page }),
              current: parseInt(page),
              defaultPageSize: data?.perPage,
              pageSize: data?.perPage,
              showSizeChanger: false
            }}
            />
        </Col>
      </Row>
      <Row>
        {/* <Col>
          <CustomPagination
            totPages={parseInt(data.hydrantsPages)}
            currentPage={parseInt(page)}
            pageClicked={(number) => setSearchParams({ page: number })} />
        </Col> */}
      </Row>
    </PrivateComponent>
  );
}