import React from "react";
import { Outlet } from "react-router-dom";
import { AdminComponent } from "../../components/Authorization/AdminComponent";
import { Container, Row, Col } from "react-bootstrap";

export const AdminInspectionsPage = () => {
  return (
    <AdminComponent>
      <Container className='mt-5'>
        <Outlet />
      </Container>
    </AdminComponent>
  )
}
