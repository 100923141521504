import { gql } from "@apollo/client";

export const INDEX = gql`
  query inspectionsExportJobs($ids: [Int!]){
    inspectionsExportJobs(
      ids: $ids
    ) {
      id
      status
      progress
      zipFileUrl
    }
  }
`;

export const SHOW = gql`
  query inspectionsExportJob($id: ID!){
    inspectionsExportJob(
      id: $id
    ) {
      id
      status
      progress
      format
      zipFileUrl
    }
  }
`;