import { gql } from "@apollo/client";

export const INDEX = gql`
  query hydrants($latitude: String, $longitude: String, $page: Int, $paginate: Boolean){
    hydrantsTotal(
      latitude: $latitude,
      longitude: $longitude
    )
    perPage
    limit
    hydrants(
      latitude: $latitude,
      longitude: $longitude,
      page: $page,
      paginate: $paginate,
    ) {
      id
      objectCode
      district
      street
      latitude
      longitude
      specialType
      lastInspectionAt
    }
  }
`;

export const SHOW = gql`
  query hydrant($id: ID!){
    hydrant(
      id: $id
    ) {
      id
      objectCode
      district
      street
      latitude
      longitude
      specialType
      lastInspectionAt
    }
  }
`;

export const SHOW_WITH_INSPECTIONS = gql`
  query hydrantWithInspections($id: ID!){
    hydrant(
      id: $id
    ) {
      id
      objectCode
      district
      inspections {
        id
        createdAt
      }
    }
  }
`;
