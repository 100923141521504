import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../../context/auth';

export const PublicComponent = ({ children }) => {
  if (isAuthenticated()) {
    return <Navigate to="/" replace />;
  }

  return children;
}
