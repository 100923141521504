import React from 'react';
import { createContext, useState } from 'react';

export const AuthContext = createContext({});

export const isAuthenticated = () => {
  return localStorage.getItem("accessToken");
}

export const canAccessAdminUI = () => {
  return localStorage.getItem("canAccessAdminUI") === "true";
}

export const Auth = ({children}) => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"));
  const [canAccessAdminUI, setCanAccessAdminUI] = useState(localStorage.getItem("canAccessAdminUI") === "true");

  return (
    <AuthContext.Provider value={{
      accessToken,
      canAccessAdminUI,
      authorize: (accessToken, isAdmin) => {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("canAccessAdminUI", isAdmin);
        setAccessToken(accessToken);
        setCanAccessAdminUI(isAdmin);
      },
      logout: () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem("canAccessAdminUI");
        setAccessToken(null);
        setCanAccessAdminUI(null);
      }
    }}>
      {children}
    </AuthContext.Provider>
  );
}
