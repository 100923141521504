import React, { useState } from "react";
import { Alert } from 'react-bootstrap';

export default function HydrantsImportJobDetails(props) {
  const [detailsHidden, setDetailsHidden] = useState(true);
  if (!props.importHydrantsJob) { return null; }

  if (props.importHydrantsJob.status == "completed") {
    return (<Alert variant="success">Plik zaimportowany</Alert>);
  }

  const showDetails = () => {
    setDetailsHidden(false);
  }

  const hideDetails = () => {
    setDetailsHidden(true);
  }

  if (props.importHydrantsJob.status == "error") {
    return (<Alert variant="danger">
      {props.importHydrantsJob.importError}<br/>

      {detailsHidden ? <a onClick={showDetails} href="#">Pokaż szczegóły</a> : ''}
      {detailsHidden ? '' : <a onClick={hideDetails} href="#">Ukryj szczegóły</a>}
      {detailsHidden ? '' : 
        <div>
          {
            props.importHydrantsJob.hydrantsImportJobsErrors.map((error) => {
              return (
                <div key={"error-" + error.rowNumber}>{error.rowNumber}: {error.message}</div>
              )
            }) 
          }
        </div>
      }
    </Alert>);
  }

  return null;
}