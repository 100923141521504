import React, { useEffect } from 'react'
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import { iconToDo, iconToDoSpecial, iconCompleted, iconConflict } from './Icons';
import { Row, Col, Container } from "react-bootstrap";

const icon = (status, specialType) => {
  switch(status) {
    case "TO_DO":
      if (specialType) {
        return iconToDoSpecial;
      }
      return iconToDo;
    case "CONFLICT":
      return iconConflict;
    default:
      return iconCompleted;
  }
}

const MapEventHandler = ({ setCurrentPosition, currentPosition}) => {
  const map = useMapEvents({
    moveend(event) {
      setCurrentPosition(event.target.getCenter());
    },
  });
  useEffect(() => {
    if ((map.getCenter().lat != currentPosition.lat) && (map.getCenter().lng != currentPosition.lng)) {
      map.setView(currentPosition)
    }
  }, [currentPosition]);

  return (
    <div></div>
  )
}

const Map = ({ onMarkerClick, markers, setCurrentPosition, currentPosition = { lat: 52.22864058406609, lng: 21.005172729492188 }, height, width }) => {

  const markerClickHandler = (_event, marker) => {
    if (onMarkerClick) {
      onMarkerClick(marker);
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <MapContainer style={{height: height}} center={[currentPosition.lat, currentPosition.lng]} zoom={15} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
              markers.map(marker => {
                return <Marker eventHandlers={{click: (event) => markerClickHandler(event, marker)}} key={marker.id} icon={icon(marker.status, marker.specialType)} position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}></Marker>
              })
            }
            <MapEventHandler
              setCurrentPosition={setCurrentPosition}
              currentPosition={currentPosition} />
          </MapContainer>
        </Col>
      </Row>
    </Container>
  )
};

export default Map;
